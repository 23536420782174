export const routes = {
  HOME: '',
  Rankings: 'rankings',
  DOWNLOAD: 'download',
  DISCORD: 'discord',
  ACCOUNT: 'account',
  colonyWar: 'colony-war',
  alliances: 'alliances',
  factions: 'factions',
  pvpLadder: 'pvp-ladder',
  logIn: 'log-in',
  signUp: 'sign-up',
  topUp: 'top-up',
  settings: 'settings',
  restorePassword: 'restore',
  setNewPassword: 'setNewPassword',
  paymentHistory: 'paymentHistory'
} as const;
