import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { TInitialState } from './types';

const initialState: TInitialState = {
  token: null,
  memberId: null,
  username: null,
  isFetching: false,
  loaded: false,
  error: null,
  message: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    regReq: state => {
      state.isFetching = true;
      state.loaded = false;
      state.error = null;
    },
    regError: (state, { payload }) => {
      state.isFetching = false;
      state.loaded = false;
      state.error = payload;
    },
    regResponse: (state, action) => {
      state.isFetching = false;
      state.loaded = true;
      state.token = action.payload.token;
      state.memberId = action.payload.memberId;
    },
    changePasswordReq: (state, action) => {
      state.isFetching = true;
      state.loaded = false;
      state.error = null;
    },
    changePasswordError: (state, { payload }) => {
      state.isFetching = false;
      state.loaded = false;
      state.error = payload;
    },
    changePasswordResponse: (state, action) => {
      state.isFetching = false;
      state.loaded = true;
    },
    authReq: state => {
      state.isFetching = true;
      state.loaded = false;
      state.error = null;
    },
    authError: (state, { payload }) => {
      console.log(payload);
      state.isFetching = false;
      state.loaded = false;
      state.error = payload;
    },
    authResponse: (state, action) => {
      state.isFetching = false;
      state.loaded = true;
      state.token = action.payload.token;
      state.memberId = action.payload.memberId;
    },
    logOut: state => {
      state.isFetching = false;
      state.loaded = false;
      state.token = null;
    },
    sendCodeReq: state => {
      state.isFetching = true;
    },
    sendCodeRes: state => {
      state.isFetching = false;
      state.message = 'Ok, check you mail';
    },
    sendCodeError: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },
    restoreReq: state => {
      state.isFetching = true;
    },
    restoreRes: state => {
      state.isFetching = false;
      state.message = 'Ok, check you mail';
    },
    restoreError: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },
    recoverPasswordReq: (state, action) => {
      state.isFetching = true;
    },
    recoverPasswordRes: state => {
      state.isFetching = false;
      state.message =
        'You will be redirected to the main page to log in with a new password.';
    },
    recoverPasswordError: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    }
  }
});

export const {
  authError,
  authResponse,
  regReq,
  regError,
  authReq,
  regResponse,
  logOut,
  changePasswordReq,
  changePasswordError,
  changePasswordResponse,
  sendCodeReq,
  sendCodeRes,
  sendCodeError,
  restoreReq,
  restoreRes,
  restoreError,
  recoverPasswordReq,
  recoverPasswordRes,
  recoverPasswordError
} = authSlice.actions;
export const getToken = (state: RootState) => state.auth.token;
export const getLoaded = (state: RootState) => state.auth.loaded;
export const getError = (state: RootState) => state.auth.error;
export const getMessage = (state: RootState) => state.auth.message;
export const getIsFetching = (state: RootState) => state.auth.isFetching;
export default authSlice.reducer;
