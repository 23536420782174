import { instance } from './pagesApi/pagesAPI';

const getRandomElement = (arr: string | any[]) => {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
};

const CLIENT_EXE_LINKS = [
  'https://drive.google.com/file/d/1iu5O1Avl6EjRs7sQyYNKsiASzoDlhx8b/view?usp=sharing',
  'https://drive.google.com/file/d/1otIu5i18xbKv1p2XCOLZPrYb7iAYMnH-/view?usp=sharing',
  'https://drive.google.com/file/d/1hLpaVgl-u-mvfWdglFG6BysS6UJT7uRm/view?usp=sharing',
];

const getHeaders = () => ({
  headers: { 'auth-token': localStorage.getItem('Token') }
});
export const mainAPI = {
  registration(values: { username: string; password: string; email: string; }) {
    return instance
      .post(`registration`, values)
      .then(response => response.data);
  },
  login(values: { username: string; password: string }) {
    return instance.post(`auth`, values).then(response => response.data);
  },
  logOut() {
    return instance
      .post(`logout`, {}, getHeaders())
      .then(response => response.data);
  },
  getBalance() {
    return instance
      .get(`balance`, getHeaders())
      .then(response => response.data);
  },
  getСolonies() {
    return instance
      .get(`colonies`, getHeaders())
      .then(response => response.data);
  },
  getLadder() {
    return instance.get(`ladder`, getHeaders()).then(response => response.data);
  },
  getElections() {
    return instance
      .get(`elections`, getHeaders())
      .then(response => response.data);
  },
  getShop() {
    return instance.get(`shop`, getHeaders()).then(response => response.data);
  },
  getTopFactions() {
    return instance
      .get(`top-factions`, getHeaders())
      .then(response => response.data);
  },
  getTopKillcount() {
    return instance
      .get(`top-killcount`, getHeaders())
      .then(response => response.data);
  },
  buyInShop(items: Record<string, number>) {
    return instance
      .post(`shop`, { items }, getHeaders())
      .then(response => response.data);
  },
  pay(name: string, surname: string, alpha2: string, paymentMethodId: number ) {
    return instance
      .post(`pay`, { name, surname, alpha2, paymentMethodId }, getHeaders())
      .then(response => response.data);
  },
  sendCode() {
    return instance
      .post(`email`, { type: 'change' }, getHeaders())
      .then(response => response.data);
  },
  changePassword({
    oldPassword,
    newPassword,
    code
  }: {
    oldPassword: string;
    newPassword: string;
    code: string;
  }) {
    return instance
      .post(
        `change-password`,
        { type: 'change', oldPassword, newPassword, code },
        getHeaders()
      )
      .then(response => response.data);
  },
  restorePassword({ email }: { email: string }) {
    return instance
      .post(`email`, { type: 'recover', email }, getHeaders())
      .then(response => response.data);
  },
  recoverPassword({
    newPassword,
    emailCode
  }: {
    newPassword: string;
    emailCode: string;
  }) {
    return instance
      .post(
        `recover-password`,
        { type: 'recover', newPassword, emailCode },
        getHeaders()
      )
      .then(response => response.data);
  },
  getPaymentOptions() {
    return instance
    .get('payment-options', getHeaders())
    .then(response => response.data)
  },
  getCountries(search?: string) {
    return instance
    .get(search ? `countries/?search=${search}` : 'countries', getHeaders())
    .then(response => response.data)
  },
  getTransactionHistory(page?: number) {
    return instance
    .get(`transactions-history/?page=${page || 1}`, getHeaders())
    .then(response => response.data)
  },
  getDownloadLink() {
    console.log(instance.getUri());
    return instance
    .get(`download-links?type=googleDrive`, getHeaders())
    .then(response => response.data)
  },
  getTorrentLink() {
   return instance.getUri() + '/download-links?type=torrent';
  },
  getAdditionalManualLink() {
   // return `https://ge-classique.com/download-client/`;
    return getRandomElement(CLIENT_EXE_LINKS);
  }
};
