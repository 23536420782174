import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { ScrollBlock } from '../../components/ScrollBlock';
import { Slide } from '../../components/Slide';
import { ModalContext } from '../../context/modalContext';
import { getToken } from '../../store/slices/authSlice';
import { SlideContent1, SlideContent2, SlideContent3 } from '../../components/SlideContent';
import { routes } from '../../consts/routes';
import { getRoute } from '../../helpers/getRoute';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import logoBig from './img/logo-big.png';
import mainImg from './img/main-img.png';
import mainImg2x from './img/main-img_2x.png';
import discord from './img/discord.png';
import discord2x from './img/discord_2x.png';
import youtube from './img/youtube.png';
import youtube2x from './img/youtube_2x.png';
import imgLeft from './img/left.png';
import imgLeft2x from './img/left_2x.png';
import imgRight from './img/right.png';
import imgRight2x from './img/right_2x.png';
import styles from './mainPage.module.scss';

export const Main = () => {
  const { onOpen } = useContext(ModalContext);
  const token = useSelector(getToken);
  const [isDragging, setIsDragging] = useState(false);
  const [isCopiedMail, setIsCopiedMail] = React.useState(false);
  const scrollRef = useRef<HTMLDivElement>();
  const navigate = useNavigate();

  useEffect(() => {
    let isDragStart = false;
    let startScrollY = 0;
    let scrollTarget = scrollRef.current.scrollTop;
    let lastScroll = scrollRef.current.scrollTop;

    const handleDown = () => {
      isDragStart = true;
      startScrollY = scrollRef.current.scrollTop;
      scrollTarget = scrollRef.current.scrollTop;
      lastScroll = scrollRef.current.scrollTop;
      setIsDragging(true);
    }

    const getNextScrollPosition = (threshold: number, startPos: number, endPos: number) => {
      const totalSlide = 3;
      const currentSlide = Math.floor(startPos / scrollRef.current.scrollHeight * totalSlide + 0.5);
      const direction = endPos - startPos;
      const nextPosition = (currentSlide + ((Math.abs(direction) < threshold) ? 0 : Math.sign(direction))) * (scrollRef.current.scrollHeight / totalSlide);
      return nextPosition;
    }

    const handleUp = () => {
      const totalSlide = 3;
      const threshold = 10;
      const currentSlide = Math.floor(startScrollY / scrollRef.current.scrollHeight * totalSlide + 0.5);
      const direction = scrollRef.current.scrollTop - startScrollY;
      const nextPosition = (currentSlide + ((Math.abs(direction) < threshold) ? 0 : Math.sign(direction))) * (scrollRef.current.scrollHeight / totalSlide);
      scrollTarget = nextPosition;
      isDragStart = false;
      setIsDragging(false);
    }
    const handleMove = (evt: MouseEvent) => {
      if (isDragStart && scrollRef.current) {
        scrollTarget -= evt.movementY;
      }
    }
    const handleDragStart = (evt: MouseEvent) => {
      evt.preventDefault();
    }
    let frame = 0;
    let speed = 0;
    let speedLimit = 30;
    let acceleration = 0.25;
    const render = (time: number) => {
      frame = requestAnimationFrame((_time: number) => {
        if (!Number.isNaN(scrollTarget) && scrollRef.current) {
          const distance = scrollRef.current.scrollTop - scrollTarget;
          speed += Math.abs(distance) * acceleration;
          if (speed > speedLimit) {
            speed = speedLimit;
          }
          const step = Math.sign(distance) * speed;
          if (Math.abs(distance) < Math.abs(step)) {
            speed = 0;
          }
          scrollRef.current.scrollBy(0, (Math.abs(distance) < Math.abs(step)) ? -distance : -step);
          lastScroll = scrollRef.current.scrollTop;
        }
        render(_time);
      })
    }
    render(Date.now());

    const handleScroll = (evt: MouseEvent) => {
      const direction = scrollRef.current.scrollTop - lastScroll;
      if (!isDragStart && direction) {
        scrollTarget = getNextScrollPosition(0.01, lastScroll, scrollRef.current.scrollTop);
      }
    }
    const handleTouchStart = () => {
      handleDown();
    }

    const handleTouchMove = (evt: TouchEvent) => {
      handleMove(evt.touches[0] as any);
    }

    const handleTouchEnd = () => {
      handleUp();
    }

    const currentScroll = scrollRef.current;
    window.addEventListener('dragstart', handleDragStart);
    window.addEventListener('mousedown', handleDown);
    window.addEventListener('mouseup', handleUp);
    window.addEventListener('mousemove', handleMove);
    currentScroll?.addEventListener('scroll', handleScroll);

    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);
    window.addEventListener('touchcancel', handleTouchEnd);

    return () => {
      window.removeEventListener('dragstart', handleDragStart);
      window.removeEventListener('mousedown', handleDown);
      window.removeEventListener('mouseup', handleUp);
      window.removeEventListener('mousemove', handleMove);
      currentScroll?.removeEventListener('scroll', handleScroll);
      setIsDragging(false);
      cancelAnimationFrame(frame);

      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
      window.removeEventListener('touchcancel', handleTouchEnd);
    }
  }, [scrollRef.current]);

  const onCopyMail = () => {
    setIsCopiedMail(true);
    setTimeout(() => setIsCopiedMail(false), 3000);
  };

  return (
    <div ref={scrollRef} className={[styles.wrapper, !isDragging ? styles.wrapperDrag : ''].join(' ')}>
      <div id="first" className={[styles.block, styles.first].join(' ')}>
        <div className={styles.firstContent}>
          <div className={styles.left}>
            <div className={styles.imageWrap}>
              <img className={styles.mainImage} src={mainImg} srcSet={`${mainImg2x} 2x`} alt="" />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.logoWrap}>
              <img className={styles.logoBig} src={logoBig} alt="" />
            </div>
            <div className={styles.gradientWrap}>
              <div className={styles.textContent}>
                <div className={styles.title}>
                  Welcome to the first <br /> Granado Espada classic server!
                </div>
                <div className={styles.mainText}>
                  Join us on the journey from version 2.0 with original content.
                </div>
                <Button className={styles.mainButton} onClick={() => {
                  navigate(getRoute(routes.DOWNLOAD))
                }}>Download</Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="second" className={[styles.block, styles.second].join(' ')}>
        <ScrollBlock scrollOptions={{ slideByItem: 1 }}>
          <Slide >
            <SlideContent1 />
          </Slide>

          <Slide >
            <SlideContent2 />
          </Slide>

          <Slide >
            <SlideContent3 />
          </Slide>
        </ScrollBlock>
      </div>

      <div id="last" className={[styles.block, styles.last].join(' ')}>
        <div className={styles.lastContent}>
          <div className={styles.top}>
            <Button className={styles.btn} onClick={() => {
              navigate(getRoute(routes.DOWNLOAD))
            }}>Download</Button>
            {!token && (
              <Button className={styles.btn} onClick={() => {
                navigate(getRoute(routes.signUp))
              }}>Register account</Button>
            )}
          </div>
          <div className={styles.bottom}>
            <div className={styles.links}>
              <Link target="_blank" to="https://discord.gg/QvHz4cjags">
                <img className={styles.linkIcon} src={discord} srcSet={`${discord2x} 2x`} alt="discord" />
              </Link>
              <Link target="_blank" to="https://youtube.com/@geclassique">
                <img className={styles.linkIcon} src={youtube} srcSet={`${youtube2x} 2x`} alt="youtube" />
              </Link>
            </div>
            <div className={styles.contacts}>
              Have a question?
              <br /> Feel free to send mail:
              <br />
              <a className={styles.mailLink} href="mailto:sirlyndon@tutamail.com">sirlyndon@tutamail.com</a>
              <CopyToClipboard text={"sirlyndon@tutamail.com"} onCopy={onCopyMail}>
                <button className={styles.copyMailBtn}>{!isCopiedMail ? 'Copy e-mail' : 'Copied!'}</button>
              </CopyToClipboard>
            </div>

          </div>
        </div>
        <img className={styles.imgLeft} src={imgLeft} srcSet={`${imgLeft2x} 2x`} alt="left" />
        <img className={styles.imgRight} src={imgRight} srcSet={`${imgRight2x} 2x`} alt="right" />
      </div>
    </div>
  );
};
