import { ScrollDirection } from '../configs/consts';
import { TScrollOptions } from '../types';

const SCROLL_THRESHOLD = 5;

export const getScrollLeftValueSlideByItem = (
  direction: ScrollDirection,
  scrollbar: HTMLDivElement,
  scrollOptions: TScrollOptions
) => {
  if (!scrollOptions.slideByItem)
    return { scrollLeft: null, toScrollElementIndex: null };

  const items = Array.from(scrollbar.childNodes) as Element[];
  const {
    right: scrollbarRight,
    left: scrollbarLeft
  } = scrollbar.getBoundingClientRect();

  let scrollLeft: number | null = null;
  let toScrollElementIndex: number | null = null;
  const { slideByItem } = scrollOptions;

  if (direction === ScrollDirection.right) {
    let leftViewElementScroll = 0;

    items.forEach((node, index) => {
      const { right } = node.getBoundingClientRect();

      if (!leftViewElementScroll && scrollbarLeft < right)
        leftViewElementScroll =
          items[index + 1].getBoundingClientRect().left - scrollbarLeft;

      if (scrollLeft === null && right - scrollbarRight >= SCROLL_THRESHOLD) {
        // определяем индекс элемента к которому необходимо скролить
        toScrollElementIndex = Math.min(
          items.length - 1,
          index + slideByItem - 1
        );
        const { right: scrollElementRight } = items[
          toScrollElementIndex
        ].getBoundingClientRect();

        const scrollValue = Math.max(
          scrollElementRight - scrollbarRight,
          leftViewElementScroll
        );

        scrollLeft = scrollbar.scrollLeft + scrollValue;
      }
    });
  }

  if (direction === ScrollDirection.left) {
    let rightViewElementScroll = 0;

    items.reverse().forEach((node, index) => {
      const { left } = node.getBoundingClientRect();

      if (!rightViewElementScroll && scrollbarRight > left)
        rightViewElementScroll =
          scrollbarRight - items[index + 1].getBoundingClientRect().right;

      if (scrollLeft === null && left - scrollbarLeft <= -SCROLL_THRESHOLD) {
        toScrollElementIndex = Math.min(
          items.length - 1,
          index + slideByItem - 1
        );
        const { left: scrollElementLeft } = items[
          toScrollElementIndex
        ].getBoundingClientRect();

        const scrollValue = Math.max(
          Math.abs(scrollElementLeft) + scrollbarLeft,
          rightViewElementScroll
        );

        scrollLeft = scrollbar.scrollLeft - scrollValue;
      }
    });
  }

  return { scrollLeft, toScrollElementIndex };
};

export const debounce = (timeout: number) => {
  let timerId: any | null = null;

  return (cb: () => void) => {
    if (timerId) clearInterval(timerId);

    timerId = setTimeout(() => {
      timerId = null;
      cb();
    }, timeout);
  };
};
