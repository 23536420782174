import React, { ReactNode } from 'react';
import cn from 'classnames';
import btn from './button.png';
import buttonHover from './buttonHover.png';
import styles from './button.module.scss';

interface IButtonProps {
  className?: string;
  children?: ReactNode;
  onClick: (e: any) => void;
  disabled?: boolean;
}

export const Button = ({children, className = '', onClick, disabled = false}: IButtonProps) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={cn(styles.button, className)}
      type="button">
      <img className={styles.buttonImg} src={btn} alt="btn" />
      <span className={styles.title}>{children}</span>
      <img className={styles.buttonImg} src={buttonHover} alt="btn" />
    </button>
  );
};
