import {
  composeValidators,
  maxLength,
  passwordValidate,
  required,
  validateEmail,
  validateLogin
} from '../../helpers/validators';
import { authReq, changePasswordReq, recoverPasswordReq, regReq, restoreReq } from '../../store/slices/authSlice';

export type TFields = {
  name: string;
  label: string;
  type?: string;
  select?: boolean;
  validate: (e?: any) => string | undefined;
  helper?: string;
};

export const generalFields = [
  {
    name: 'username',
    label: 'Username:',
    autocomplete: 'off',
    // validate: composeValidators(required, maxLength(12)),
    validate: validateLogin
  },
  {
    name: 'password',
    label: 'Password:',
    type: 'password',
    validate: composeValidators(passwordValidate, required),
    helper:
      'The password must consist of latin letters (a-zA-Z), numbers (0-9) and an underscore (_)'
  }
];
export const regFields = [
  generalFields[0],
  {
    name: 'email',
    label: 'E-mail:',
    type: 'email',
    autocomplete: 'email',
    validate: validateEmail
  },
  generalFields[1],
  {
    name: 'confirm-password',
    label: 'Confirm password:',
    type: 'password',
    validate: composeValidators(passwordValidate, required),
    helper:
      'The password must consist of latin letters (a-zA-Z), numbers (0-9) and an underscore (_)'
  } 
];
export const authFields: TFields[] = [...generalFields];

export const restoreFields = [
  {
    name: 'email',
    label: 'E-mail:',
    type: 'email',
    autocomplete: 'email',
    validate: validateEmail,
  }
];

export const fieldsConfig = {
  auth: authFields,
  reg: regFields,
  restore: restoreFields
};

export const actionBtnText = {
  auth: 'Sign in',
  reg: 'Sign up',
  restore: 'Restore'
};

export const headerText = {
  auth: 'Sign in',
  reg: 'Sign up',
  restore: 'Restore password'
};

export const action = {
  auth: authReq,
  reg: regReq,
  restore: restoreReq,
  change: changePasswordReq,
  recover: recoverPasswordReq
};

export const changePasswordFields = [
  {
    name: 'oldPassword',
    label: 'Old password:',
    type: 'password',
    validate: composeValidators(passwordValidate, required),
    helper:
      'The password must consist of latin letters (a-zA-Z), numbers (0-9) and an underscore (_)'
  },
  {
    name: 'newPassword',
    label: 'New password:',
    type: 'password',
    validate: composeValidators(passwordValidate, required),
    helper:
      'The password must consist of latin letters (a-zA-Z), numbers (0-9) and an underscore (_)'
  }
]

export const emailCodeFields = [
  {
    name: 'code',
    label: 'Code:',
    type: 'text',
    validate: composeValidators(required),
    helper:
      'Code from email'
  }
]

export const recoverPasswordFields = [
  {
    name: 'newPassword',
    label: 'New password:',
    type: 'password',
    validate: composeValidators(passwordValidate, required),
    helper:
      'The password must consist of latin letters (a-zA-Z), numbers (0-9) and an underscore (_)'
  },
  {
    name: 'confirmPassword',
    label: 'Confirm password:',
    type: 'password',
    validate: composeValidators(passwordValidate, required),
    helper:
      'The password must consist of latin letters (a-zA-Z), numbers (0-9) and an underscore (_)'
  },
]

export const transactionPopupFields = [
  {
    name: 'name',
    label: 'Name:',
    type: 'text',
    validate: required
  },
  {
    name: 'surname',
    label: 'Surname:',
    type: 'text',
    validate: required
  },
]
