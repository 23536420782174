import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components/Button';
import { Loader } from '../../../components/Loader';
import { TextAdapter } from '../../../components/TextAdapter';
import {
  getError,
  getIsFetching,
  getMessage,
  getToken,
  regResponse
} from '../../../store/slices/authSlice';
import {
  TFields,
  fieldsConfig,
  action,
  actionBtnText,
  headerText
} from './../configs';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../consts/routes';
import { getRoute } from '../../../helpers/getRoute';
import { useModal } from '../../../components/Modal';
import { MessagePopup } from '../../../components/MessagePopup/MessagePopup';
import { mainAPI } from '../../../api/mainAPI';
import { serverMessages } from '../../../translates/serverMessages';
import imgLeft from '../img/left.png';
import imgRight from '../img/right.png';
import imgLeft2x from '../img/left_2x.png';
import imgRight2x from '../img/right_2x.png';
import styles from './../authPage.module.scss';

export const LogIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const { isShow, Modal, onOpen, onClose } = useModal();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (token) {
      navigate(getRoute(routes.HOME))
    }
  }, [token]);

  const fields: TFields[] = fieldsConfig.auth;
  const onSubmit = (v: any) => {
    sendRequest(v.username, v.password);
  };
  
  const sendRequest = async (username: string, password: string) => {
    setIsFetching(true);
    setError('');
    setMessage('');
    try {
      const data = await mainAPI.login({username, password});
      if (data) {
        localStorage.setItem('Token', data.data.token);
        localStorage.setItem('MemberId', data.data.memberId);
        dispatch(regResponse(data.data));
        setIsFetching(false);       
      }
    } catch (e) {
      setIsFetching(false);
      const errorMap: Record<string, string> = { 'Not found': serverMessages.notFound };
      const currentError = errorMap[e?.response?.data?.data?.error];
      setError(currentError || serverMessages.errorLogin);
      onOpen();
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>Login</div>
        </div>

        {isFetching ? (
          <Loader />
        ) : (
          <div className={styles.gradientWrap}>
            <div className={styles.formContent}>
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => {
                  return (
                    <div className={[styles.formContainer, styles.formContainerLogin].join(' ')}>
                      <form className={[styles.form, styles.formLogin].join(' ')} onSubmit={handleSubmit}>
                        {fields.map(({ name, label, type, validate, helper }) => (
                          <div key={name} className={styles.fieldContainer}>
                            <Field
                              type={type}
                              component={TextAdapter}
                              validate={validate}
                              name={name}
                              helper={helper}
                              label={label}                              
                              customStyles={styles}
                            />
                          </div>
                        ))}
                      </form>
                      <button type="button" className={styles.helpBtn}
                        onClick={() => { navigate(getRoute(routes.restorePassword)) }}>
                        Forgot password?
                      </button>
                      <div className={[styles.buttonsWrap, styles.buttonsWrapLogin].join(' ')}>
                        <Button className={[styles.regBtn, styles.regBtnLogin].join(' ')}
                          onClick={() => {
                            navigate(getRoute(routes.signUp))                            
                          }}>
                          Sign up
                        </Button>

                        <Button className={[styles.regBtn, styles.regBtnLogin].join(' ')} onClick={handleSubmit}>
                          Login
                        </Button>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        )}
      </div>
      <img className={styles.imgLeft} src={imgLeft} srcSet={`${imgLeft2x} 2x`} alt="left" />
      <img className={styles.imgRight} src={imgRight} srcSet={`${imgRight2x} 2x`} alt="right" />
      <Modal isShow={isShow}>
        <MessagePopup title={error ? 'Error' : 'Success'} message={error || message} handler={onClose} />
      </Modal>
    </div>
  );
};
