import { put, takeLatest } from 'redux-saga/effects';
import { mainAPI } from '../../../api/mainAPI';
import {
  regReq,
  regError,
  authReq,
  authError,
  authResponse,
  regResponse,
  logOut,
  changePasswordReq,
  changePasswordError,
  changePasswordResponse,
  sendCodeReq,
  sendCodeRes,
  sendCodeError,
  restoreReq,
  restoreRes,
  restoreError,
  recoverPasswordReq,
  recoverPasswordRes,
  recoverPasswordError
} from '../../slices/authSlice';
import { transactionHistoryClear } from '../../slices/userSlice';

export function* regSaga({
  payload
}: {
  payload: {
    username: string;
    password: string;
  };
}) {
  try {
    // @ts-ignore
    const data = yield mainAPI.registration(payload);
    if (data.data) {
      localStorage.setItem('Token', data.data.token);
      localStorage.setItem('MemberId', data.data.memberId);
      yield put(regResponse(data.data));
    } else {
      yield put(regError(data.error.message));
    }
  } catch (e) {
    // @ts-ignore
    yield put(regError(e?.response?.data?.data?.error));
  }
}

export function* authSaga({
  payload
}: {
  payload: {
    username: string;
    password: string;
  };
}) {
  try {
    // @ts-ignore
    const data = yield mainAPI.login(payload);
    if (data.data) {
      localStorage.setItem('Token', data.data.token);
      localStorage.setItem('MemberId', data.data.memberId);
      yield put(authResponse(data.data));
    } else {
      yield put(regError(data.error.message));
    }
  } catch (e) {
    // @ts-ignore
    yield put(authError(e?.response?.data?.data?.error));
  }
}

export function* changePasswordSaga({
  payload
}: {
  payload: {
    oldPassword: string;
    newPassword: string;
    code: string;
  };
}) {
  try {
    // @ts-ignore
    const data = yield mainAPI.changePassword(payload);
    if (data.data) {
      yield put(changePasswordResponse(data.data));
    } else {
      yield put(changePasswordError(data.error.message));
    }
  } catch (e) {
    // @ts-ignore
    yield put(changePasswordError(e?.response?.data?.data?.error));
  }
}

export function* logoutSaga() {
  try {
    // @ts-ignore
    const data = yield mainAPI.logOut();
    if (data.data) {
      yield put(regResponse(data.data));
      yield put(transactionHistoryClear())
      localStorage.removeItem('Token');
      localStorage.removeItem('MemberId');
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export function* sendCodeSaga() {
  try {
    // @ts-ignore
    const data = yield mainAPI.sendCode();
    if (data) {
      yield put(sendCodeRes());
    }
  } catch (e) {
    // @ts-ignore
    yield put(sendCodeError(e?.response?.data?.data?.error));
  }
}

export function* restoreSaga({ payload }: { payload: { email: string } }) {
  try {
    // @ts-ignore
    const data = yield mainAPI.restorePassword(payload);
    if (data) {
      yield put(restoreRes());
    }
  } catch (e) {
    // @ts-ignore
    yield put(restoreError(e?.response?.data?.data?.error));
  }
}

export function* recoverPassworSaga({
  payload
}: {
  payload: { emailCode: string; newPassword: string };
}) {
  try {
    // @ts-ignore
    const data = yield mainAPI.recoverPassword(payload);
    if (data) {
      yield put(recoverPasswordRes());
    }
  } catch (e) {
    // @ts-ignore
    yield put(recoverPasswordError(e?.response?.data?.data?.error));
  }
}

export function* authSagaWatcher() {
  // @ts-ignore
  yield takeLatest(regReq, regSaga);
  // @ts-ignore
  yield takeLatest(authReq, authSaga);
  // @ts-ignore
  yield takeLatest(changePasswordReq, changePasswordSaga);
  // @ts-ignore
  yield takeLatest(sendCodeReq, sendCodeSaga);
  // @ts-ignore
  yield takeLatest(restoreReq, restoreSaga);
  // @ts-ignore
  yield takeLatest(recoverPasswordReq, recoverPassworSaga);
  // @ts-ignore
  yield takeLatest(logOut, logoutSaga);
}
