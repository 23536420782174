import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { TInitialState } from './types';

const initialState: TInitialState = {
  balance: 0,
  shop: [],
  isFetching: false,
  loaded: false,
  transactionHistory: [],
  transactionMap: {},
  loadedPages: 0
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    balanceReq: state => {
      state.isFetching = true;
      state.loaded = false;
    },
    balanceError: state => {
      state.isFetching = false;
      state.loaded = false;
    },
    balanceResponse: (state, action) => {
      state.isFetching = false;
      state.loaded = true;
      state.balance = action.payload.balance;
    },
    shopReq: state => {
      state.isFetching = true;
      state.loaded = false;
    },
    shopError: state => {
      state.isFetching = false;
      state.loaded = false;
    },
    shopResponse: (state, action) => {
      state.isFetching = false;
      state.loaded = true;
      state.shop = action.payload;
    },
    buyShopReq: (state, action) => {
      state.isFetching = true;
      state.loaded = false;
    },
    buyShopError: state => {
      state.isFetching = false;
      state.loaded = false;
    },
    buyShopResponse: (state, { payload }) => {
      state.isFetching = false;
      state.loaded = true;
      state.balance = payload.balance;
    },
    payReq: state => {
      state.isFetching = true;
      state.loaded = false;
    },
    payError: state => {
      state.isFetching = false;
      state.loaded = false;
    },
    payResponse: (state, action) => {
      state.isFetching = false;
      state.loaded = true;
    },
    transactionHistoryReq: (state, action) => {
      state.isFetching = true;
      state.loaded = false;
    },
    transactionHistoryResponse: (state, action) => {
      state.isFetching = false;
      state.loadedPages = Math.max(state.loadedPages, action.payload.page);
      state.loaded = true;
      state.transactionHistory = action.payload.data.history;
      action.payload.data.history.forEach((it: any) => {
        state.transactionMap[it.id] = it;
      })      
    },
    transactionHistoryClear: (state) => {
      state.isFetching = false;
      state.loadedPages = 0;
      state.loaded = true;
      state.transactionHistory = [];      
      state.transactionMap = {};        
    },
    transactionHistoryError: state => {
      state.isFetching = false;
      state.loaded = false;
    }
  }
});

export const {
  balanceReq,
  balanceError,
  balanceResponse,
  shopReq,
  shopError,
  shopResponse,
  buyShopReq,
  buyShopError,
  buyShopResponse,
  payReq,
  payError,
  payResponse,
  transactionHistoryReq,
  transactionHistoryResponse,
  transactionHistoryClear,
  transactionHistoryError
} = userSlice.actions;
export const getBalance = (state: RootState) => state.user.balance;
export const getShop = (state: RootState) => state.user.shop;
export const getIsFetching = (state: RootState) => state.user.isFetching;
export const getUsername = (state: RootState) => state.auth.memberId;
export const getTransactionHistory = (state: RootState) => state.user.transactionHistory;
export const getTransactionMap = (state: RootState) => state.user.transactionMap;
export const getLoadedPages = (state: RootState) => state.user.loadedPages;
export default userSlice.reducer;
