import React, { useEffect, useRef, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Button } from '../../../../components/Button';
import { TextAdapter } from '../../../../components/TextAdapter';
import { TFields, transactionPopupFields } from '../../../Auth/configs';
import { Loader } from '../../../../components/Loader';
import { mainAPI } from '../../../../api/mainAPI';
import { useModal } from '../../../../components/Modal';
import { MessagePopup } from '../../../../components/MessagePopup/MessagePopup';
import { useNavigate } from 'react-router-dom';
import { serverMessages } from '../../../../translates/serverMessages';
import { CountryType } from '../../../../types/countryType';
import dropdownTriangle from './dropdownTriangle.svg';
import styles from './transactionPopup.module.scss';
import { required } from '../../../../helpers/validators';

interface IEmailCodeProps {
  paymentMethodId: number;
  activeTab: string;
  paymentAmount: number;
  handler: () => void;
}

const checkParent = (el: Node, parent: Node) => {
  if (el != parent) {
    if (!el.parentNode) {
      return false;
    }
    return checkParent(el.parentNode, parent);
  }
  return true;
}

export const TransactionPopup = ({ paymentMethodId, activeTab, paymentAmount, handler }: IEmailCodeProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const fields: TFields[] = transactionPopupFields;
  const { isShow, Modal, onOpen, onClose } = useModal();
  const [countries, setCountries] = useState<Array<CountryType>>([]);
  const [allCountries, setAllCountries] = useState<Array<CountryType>>([]);
  const [countrySearch, setCountrySearch] = useState('');
  const [isCountriesShow, setIsCountriesShow] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');
  const [formError, setFormError] = useState('');
  const [isSendedForm, setIsSendedForm] = useState(false);

  const getCountries = async (search?: string) => {
    const data = await mainAPI.getCountries(search);
    setCountries(data.data.countries);
  }

  const getAllCountries = async () => {
    const data = await mainAPI.getCountries();
    setAllCountries(data.data.countries);
  }

  const sendRequest = async (name: string, surname: string, alpha2: string) => {
    try {
      const data = await mainAPI.pay(name, surname, alpha2, paymentMethodId);
      if (data) {
        setMessage(serverMessages.transactionSuccess);
        setPaymentLink(data.data.redirectTo);
      }
    } catch (e) {
      setError(serverMessages.transactionError);
    }
  }

  useEffect(() => {
    if (countrySearch) {
      const id = setTimeout(() => { getCountries(countrySearch) }, 500);
      return () => {
        clearTimeout(id);
      }
    } else {
      setCountries(allCountries);
    }
  }, [countrySearch, allCountries]);

  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    if (message || error) {
      onOpen();
    }
  }, [message, error]);

  const onSubmit = (v: any) => {
    setMessage('');
    setError('');
    sendRequest(v.name, v.surname, v.alpha2);
  };

  const countriesInput = useRef<HTMLInputElement>();
  const countriesBtn = useRef<HTMLButtonElement>();
  useEffect(() => {
    if (!isCountriesShow) {
      return;
    }
    const close = (evt: MouseEvent) => {
      if (evt.target != countriesInput.current && !checkParent(evt.target as Node, countriesBtn.current)) {
        setIsCountriesShow(false);
        window.removeEventListener('click', close);
      }
    }
    window.addEventListener('click', close, true);
    return () => window.removeEventListener('click', close);
  }, [isCountriesShow]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>{`${paymentAmount} Coins`}</div>
          <p className={styles.subtitle}>{`(by ${activeTab[0] + activeTab.slice(1).toLowerCase()})`}</p>
        </div>
        {isFetching ? (
          <Loader />
        ) : (
          <>
            <Form
              validate={(values: any) => {
                const errors: any = {};
                errors.name = required(values.name);
                errors.surname = required(values.surname);
                errors.alpha2 = required(values.alpha2);
                const priorityError = (errors.name || errors.surname || errors.alpha2) ? 'Please, fill in all the fields.' : '';
                setFormError(priorityError);
                return errors;
              }}
              onSubmit={onSubmit}
              mutators={{
                setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
                  changeValue(state, fieldName, () => fieldVal);
                }
              }}
              render={({ handleSubmit, form }) => {
                return (
                  <>
                    <div className={styles.gradientWrap}>
                      <div className={styles.formContent}>
                        <div className={styles.textContent}>
                          <p className={styles.textItem}>To initiate a transaction, please provide buyer details. We do not store this information - it will only be used to create the payment.</p>
                        </div>

                        <div className={styles.formContainer}>
                          <form className={styles.form} onSubmit={() => {
                            handleSubmit();
                            setIsSendedForm(true);
                          }}>
                            {fields.map(({ name, label, type, validate, helper }) => (
                              <div key={name} className={styles.fieldContainer}>
                                <Field
                                  type={type}
                                  component={TextAdapter}
                                  validate={validate}
                                  name={name}
                                  helper={helper}
                                  label={label}
                                  customStyles={styles}
                                />
                              </div>
                            ))}

                            <div className={styles.container}>
                              <label className={styles.label} htmlFor="countriesInput">Country:</label>
                              <div className={styles.dropDownWrap}>
                                <div className={styles.dropDownInputWrap}>
                                  <input className={[styles.input, styles.countryInput].join(' ')}
                                    ref={countriesInput} type="text" id="countriesInput"
                                    value={form.getState().values['countryName'] || ''}
                                    onClick={() => setIsCountriesShow(last => !last)}
                                    onChange={(e) => {
                                      form.mutators.setFormAttribute("alpha2", allCountries.find(it => it.name == e.target.value)?.alpha2 || '');
                                      form.mutators.setFormAttribute("countryName", `${e.target.value}`);                                    
                                      setCountrySearch(e.target.value);
                                    }} />
                                  <button ref={countriesBtn}
                                    type="button" className={styles.triangleBtn}
                                    onClick={() => setIsCountriesShow(last => !last)}>
                                    <img className={[styles.triangleImg, isCountriesShow ? styles.triangleImgRotate : ''].join(' ')} src={dropdownTriangle} alt="triangle arrow" />
                                  </button>
                                </div>
                             
                                <div className={[styles.dropDownGrid, isCountriesShow ? styles.dropDownGridOpen : ''].join(' ')}>
                                  <div className={styles.dropDownList}>
                                    {countries
                                      .sort((a, b) => {
                                        if (a.name.toLowerCase() < b.name.toLowerCase())
                                          return -1
                                        if (a.name.toLowerCase() > b.name.toLowerCase())
                                          return 1
                                        return 0;
                                      })
                                      .map(country => {
                                        return (
                                          <div className={styles.dropDownListItem} key={country.alpha2} onClick={() => {
                                            form.mutators.setFormAttribute("alpha2", `${country.alpha2}`);
                                            form.mutators.setFormAttribute("countryName", `${country.name}`);
                                          }}>{country.name}</div>
                                        )
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <p className={styles.error}>
                          {(formError && isSendedForm) ? formError : ''}
                        </p>
                        <div className={styles.textContent}>
                          <p className={[styles.textItem, styles.textItemBottom].join(' ')}>*By clicking “Apply” button, you agree that this is a Donation process, any attempt of refunding will result in your account being banned.</p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.buttonsWrap}>
                      <Button className={styles.regBtn} onClick={() => { handler() }}>Back</Button>
                      <Button className={styles.regBtn} onClick={() => {
                        handleSubmit();
                        setIsSendedForm(true);
                      }}>Apply</Button>
                    </div>
                  </>
                );
              }}
            />
          </>
        )}
      </div>
      <Modal isShow={isShow}>
        <MessagePopup title={error ? 'Error' : 'Success'} message={error || message} handler={() => {
          onClose();
          if (!error) {
            handler();           
            window.location.href = paymentLink;
          }
        }} />
      </Modal>
    </div>
  )
}