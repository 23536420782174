import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { TInitialState } from './types';

const initialState: TInitialState = {
  colonies: null,
  ladder: {},
  elections: null,
  faction: null,
  killCount: null,
  isFetching: false,
  loaded: false,
  lastSunday: null,
  downloadLink: '#'
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    coloniesReq: state => {
      state.isFetching = true;
      state.loaded = false;
    },
    coloniesError: state => {
      state.isFetching = false;
      state.loaded = false;
    },
    coloniesResponse: (state, action) => {
      state.isFetching = false;
      state.loaded = true;
      state.colonies = action.payload.colonies;
      state.lastSunday = action.payload.lastSunday;
    },
    ladderReq: state => {
      state.isFetching = true;
      state.loaded = false;
    },
    ladderError: state => {
      state.isFetching = false;
      state.loaded = false;
    },
    ladderResponse: (state, action) => {
      state.isFetching = false;
      state.loaded = true;
      state.ladder = action.payload;
    },
    electionsReq: state => {
      state.isFetching = true;
      state.loaded = false;
    },
    electionsError: state => {
      state.isFetching = false;
      state.loaded = false;
    },
    electionsResponse: (state, action) => {
      state.isFetching = false;
      state.loaded = true;
      state.elections = action.payload;
    },
    killCountReq: state => {
      state.isFetching = true;
      state.loaded = false;
    },
    killCountError: state => {
      state.isFetching = false;
      state.loaded = false;
    },
    killCountResponse: (state, action) => {
      state.isFetching = false;
      state.loaded = true;
      state.killCount = action.payload;
    },
    factionReq: state => {
      state.isFetching = true;
      state.loaded = false;
    },
    factionError: state => {
      state.isFetching = false;
      state.loaded = false;
    },
    factionResponse: (state, action) => {
      state.isFetching = false;
      state.loaded = true;
      state.faction = action.payload;
    },
    downloadLinkReq: state => {
      state.isFetching = true;
      state.loaded = false;
    },
    downloadLinkError: state => {
      state.isFetching = false;
      state.loaded = false;
    },
    downloadLinkResponse: (state, action) => {
      state.isFetching = false;
      state.loaded = true;
      state.downloadLink = action.payload.link;
    }
  }
});

export const {
  coloniesReq,
  coloniesError,
  coloniesResponse,
  ladderReq,
  ladderError,
  ladderResponse,
  electionsReq,
  electionsError,
  electionsResponse,
  killCountReq,
  killCountError,
  killCountResponse,
  factionReq,
  factionError,
  factionResponse,
  downloadLinkReq,
  downloadLinkError,
  downloadLinkResponse
} = generalSlice.actions;
export const getColonies = (state: RootState) => state.general.colonies;
export const getLastSunday = (state: RootState) => state.general.lastSunday;
export const getLadder = (state: RootState) => state.general.ladder;
export const getElections = (state: RootState) => state.general.elections;
export const getKillCount = (state: RootState) => state.general.killCount;
export const getFaction = (state: RootState) => state.general.faction;
export const getDownloadLink = (state: RootState) => state.general.downloadLink;
export default generalSlice.reducer;
