export const serverMessages = {
  'emailNotFound': 'The user with the specified email address has not found',
  'unknownError': 'Unknown error. Try again later.',
  'checkEmail': 'Password reset link has been sent to the specified email address.',
  'errorSignUp': 'An error occurred while creating your account. Please try again later. ',
  'errorLogin': 'An error occurred while logging into acccount. Please try again later.',
  'errorChangePassword': 'An error occurred while changing your password. Please try again later.',
  'accountCreated': 'Your account has been created',
  'passwordChanged': 'Your password has been changed',
  'alreadyInUse': 'Email already in use',
  'notFound': 'Wrong password',
  'transactionSuccess': 'You will be redirected to the payment page.',
  'transactionError': 'An error occurred while creating a transaction. Please try again later.'
}