import React, { useEffect, useState } from 'react';
import { Loader } from '../../../components/Loader';
import imgRight from './torche.png';
import imgRight2x from './torche_2x.png';
import styles from './topUp.module.scss';
import { mainAPI } from '../../../api/mainAPI';
import { Tabs } from '../../../components/Tabs';
import { useModal } from '../../../components/Modal';
import { TransactionPopup } from './TransactionPopup';

export const TopUp = () => { 
  const { isShow, Modal, onOpen, onClose } = useModal();
  const [paymentMethodId, setPaymentMethodId] = useState(-1);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');
  const [tabsData, setTabsData] = useState<any>(null);
  const [activeTab, setActiveTab] = useState('');
  const [paymentAmount, setPaymentAmount] = useState(0);

  const sendRequest = async () => {
    setIsFetching(true);
    setError('');
    try {
      const data = await mainAPI.getPaymentOptions();
      if (data) {
        setTabsData(data.data.paymentOptions);
        setIsFetching(false);
        setActiveTab(Object.keys(data.data.paymentOptions)[0]);
      }
    } catch (e) {
      setIsFetching(false);
      setError(e?.response?.data?.data?.error);
    }
  }

  useEffect(() => {
    sendRequest();
  }, []);
  
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>Top up</div>
        </div>
        {(isFetching || !tabsData) ? (
          <Loader />
        ) : (
          <Tabs tabsData={tabsData}  activeTab={activeTab} setActiveTab={setActiveTab} 
          onPay={(options) => {
            setPaymentMethodId(options.paymentMethodId);
            setPaymentAmount(options.tokenAmount);
            onOpen();
          }}/>        
        )}    
      </div>
      <img className={styles.imgRight} src={imgRight} srcSet={`${imgRight2x} 2x`} alt="right" />
      <Modal isShow={isShow} onClose={onClose}>
        <TransactionPopup activeTab={activeTab} paymentAmount={paymentAmount} paymentMethodId={paymentMethodId} handler={onClose} />
      </Modal>
    </div>
  )
}