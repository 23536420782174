import React, { useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '../../../components/Table';
import {
  killCountReq,
  factionReq,
  getKillCount,
  getFaction
} from '../../../store/slices/generalSlice';
import styles from './factions.module.scss';

const killHeadData = [
  { width: '20%', title: 'Rank', textAlign: 'center', keyInList: 'position' },
  {
    width: '50%',
    title: 'Family',
    textAlign: 'center',
    keyInList: 'commanderName'
  },
  {
    width: '30%',
    title: 'Kills',
    textAlign: 'center',
    keyInList: 'killCount'
  }
];
const factionHeadData = [
  { width: '20%', title: 'Rank', textAlign: 'center', keyInList: 'position' },
  {
    width: '50%',
    title: 'Faction',
    textAlign: 'center',
    keyInList: 'partyName'
  },
  {
    width: '30%',
    title: 'Mid Level',
    textAlign: 'center',
    keyInList: 'midLvl'
  }
];
export const Factions = () => {
  const dispatch = useDispatch();
  const killCount = useSelector(getKillCount);
  const faction = useSelector(getFaction);
  useEffect(() => {
    dispatch(killCountReq());
    dispatch(factionReq());
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.block}>
          <div className={styles.titleWrap}>
            <div className={styles.title}>Faction Top</div>
          </div>

          <div className={styles.gradientWrap}>
            <div className={styles.content}>
              <Table
                headData={factionHeadData}              
                list={faction?.map(it => ({...it, position: it.position + '.'}))}
                className={styles.tableContainer}
                customStyles={styles}
              />
            </div>
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.titleWrap}>
            <div className={styles.title}>Killcount</div>
          </div>

          <div className={styles.gradientWrap}>
            <div className={styles.content}>
              <Table
                headData={killHeadData}               
                list={killCount?.map(it => ({...it, position: it.position + '.'}))}
                className={styles.tableCcontainer}
                customStyles={styles}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
