import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './RootReducer';
import { RootWatcher } from './RootWatcher';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares)
});
sagaMiddleware.run(RootWatcher);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
