import React from 'react';
import { Button } from '../Button';
import styles from './messagePopup.module.scss';

interface IMessagePopupProps {
  message: string;
  title: string;
  handler: () => void;
}

export const MessagePopup = ({message, title, handler}: IMessagePopupProps) => { 
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.gradientWrap}>
          <div className={styles.formContent}>
            <div className={styles.textContent}>
              <p className={styles.textItem}>{message}</p>             
            </div>  
          </div>
        </div>
        <div className={styles.buttonsWrap}>
          <Button className={styles.regBtn} onClick={() => { handler() }}>Ok</Button>        
        </div>
      </div>
    </div>
  )
}
