import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../../components/Loader';
import { getElections, electionsReq } from '../../../store/slices/generalSlice';
import gabriella from './img/gabriella.png';
import gabriella2x from './img/gabriella_2x.png';
import simon from './img/simon.png';
import simon2x from './img/simon_2x.png';
import styles from './aliiances.module.scss';

export const Aliiances = () => {
  const dispatch = useDispatch();
  const data = useSelector(getElections);
  useEffect(() => {
    dispatch(electionsReq());
  }, []);

  if (!data) {
    return <Loader className={styles.loader} />;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.block}>          
            <div className={styles.titleWrap}>
              <div className={styles.title}>Republicans</div>
            </div>
            <div className={styles.gradientWrap}>
            <div className={styles.content}>
              <div className={styles.contentItem}>
                <span>President:</span>
                <span>{data.republicans.familyName}</span>
              </div>
              <div className={styles.contentItem}>
                <span>Colonies:</span>
                <span>{data.republicans.colonies}</span>
              </div>
            </div>
            </div>
          </div>

          <div className={styles.block}>           
            <div className={styles.titleWrap}>
              <div className={styles.title}>Royalists</div>
            </div>
            <div className={styles.gradientWrap}>
            <div className={styles.content}>
              <div className={styles.contentItem}>
                <span>Duke:</span>
                <span>{data.royalists.familyName}</span>
              </div>
              <div className={styles.contentItem}>
                <span>Colonies:</span>
                <span>{data.royalists.colonies}</span>
              </div>
            </div>
            </div>
          </div>
        </div>

        <div className={styles.bottom}>
        <div className={[styles.gradientWrap, styles.gradientWrapBottom].join(' ')}>
          <div className={[styles.content, styles.contentBottom].join(' ')}>
            <div className={styles.contentItem}>
              <span>Chancellor:</span>
              <span>{data.chancellor.familyName}</span>
            </div>
          </div>
          </div>
        </div>
      </div>
      <img className={styles.imgLeft} src={simon} srcSet={`${simon2x} 2x`} alt="simon" />
      <img className={styles.imgRight} src={gabriella} srcSet={`${gabriella2x} 2x`} alt="gabriella" />
    </div>
  );
};
