import React, { useCallback, useEffect, useRef, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../consts/routes';
import { getRoute, TPath } from '../../helpers/getRoute';
import { getToken } from '../../store/slices/authSlice';
import { Button } from '../Button';
import { AuthBlock } from './components/AuthBlock';
import logo from './logo.png';
import styles from './header.module.scss';

export const Header = ({ handlerAuth }: any) => {
  const [isShow, setIsShow] = useState(false);
  const [isRankingShow, setIsRankingShow] = useState(false);
  const [isAccountShow, setIsAccountShow] = useState(false);
  const token = useSelector(getToken);
  const { pathname } = useLocation();
  const getVariant = useCallback(
    (path: TPath) => (getRoute(path) === pathname ? 'outlined' : 'text'),
    [pathname]
  );
  const handlerMobileMenu = () => setIsShow(!isShow);

  const rankingButton = useRef<HTMLButtonElement>();
  useEffect(() => {
    if (!isRankingShow) {
      return;
    }
    const close = (evt: MouseEvent) => {
      if (evt.target != rankingButton.current) {
        setIsRankingShow(false);
        window.removeEventListener('click', close);
      }
    }
    window.addEventListener('click', close, true);

    return () => window.removeEventListener('click', close);
  }, [isRankingShow]);

  const accountButton = useRef<HTMLButtonElement>();
  useEffect(() => {
    if (!isAccountShow) {
      return;
    }
    const close = (evt: MouseEvent) => {
      if (evt.target != accountButton.current) {
        setIsAccountShow(false);
        window.removeEventListener('click', close);
      }
    }
    window.addEventListener('click', close, true);

    return () => window.removeEventListener('click', close);
  }, [isAccountShow]);

  return (
    <div className={styles.container}>
      <div className={styles.categories}>
        <Link className={styles.logoLink} to={getRoute(routes.HOME)}>
          <img src={logo} className={styles.logo} alt="logo" />
        </Link>
        <Link to={getRoute(routes.HOME)}>
          <div className={styles.linkItem}>Home</div>
        </Link>
      
        <div className={styles.linkItemDrop}>
          <button ref={rankingButton} className={[styles.linkItem, styles.dropDownButton, isRankingShow ? styles.dropDownButtonOpen : ''].join(' ')}
            onClick={() => {
              setIsRankingShow(last => !last);
            }
            }>Rankings</button>
          <div className={[styles.dropDownGrid, isRankingShow ? styles.dropDownGridOpen : ''].join(' ')}>
            <div className={styles.dropDownList}>
              <Link to={getRoute(routes.colonyWar)}>
                <div className={[styles.linkItem, styles.dropDownListItem].join(' ')}>Colony war</div>
              </Link>
              <Link to={getRoute(routes.alliances)}>
                <div className={[styles.linkItem, styles.dropDownListItem].join(' ')}>Alliances</div>
              </Link>
              <Link to={getRoute(routes.factions)}>
                <div className={[styles.linkItem, styles.dropDownListItem].join(' ')}>Factions</div>
              </Link>
              <Link to={getRoute(routes.pvpLadder)}>
                <div className={[styles.linkItem, styles.dropDownListItem].join(' ')}>PvP Ladder</div>
              </Link>
            </div>
          </div>
        </div>
        <Link to={getRoute(routes.DOWNLOAD)}>
          <div className={styles.linkItem}>Download</div>
        </Link>
        <Link target="_blank" to="https://discord.gg/QvHz4cjags">
          <div className={styles.linkItem}>Discord</div>
        </Link>
      </div>

      <div className={styles.right}>
        {!token && (         
          <div className={styles.linkItemDrop}>
            <button ref={accountButton}  className={[styles.linkItem, styles.dropDownButton, isAccountShow ? styles.dropDownButtonOpen : ''].join(' ')}
              onClick={() => setIsAccountShow(last => !last)}>Account</button>
            <div className={[styles.dropDownGrid, isAccountShow ? styles.dropDownGridOpen : ''].join(' ')}>
              <div className={styles.dropDownList}>
                <Link to={getRoute(routes.logIn)}>
                  <div className={[styles.linkItem, styles.dropDownListItem].join(' ')}>Log in</div>
                </Link>
                <Link to={getRoute(routes.signUp)}>
                  <div className={[styles.linkItem, styles.dropDownListItem].join(' ')}>Sign up</div>
                </Link>
              </div>
            </div>
          </div>
        )}
        {token && <AuthBlock />}

        <div className={styles.mobileMenuContainer}>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button
            className={styles.mobileBtn}
            type="button"
            onClick={handlerMobileMenu}>
            <MenuIcon className={styles.burger} />
          </button>
          <div
            className={cn(styles.mobileMenu, {
              [styles.mobileMenuHide]: !isShow
            })}>
            <Link to={getRoute(routes.HOME)}>
              <div className={styles.linkItemMob}>Home</div>
            </Link>
            <Link to={getRoute(routes.Rankings)}>
              <div className={styles.linkItemMob}>Rankings</div>
            </Link>
            <Link target="_blank" to="https://discord.gg/39hEFVAp">
              <div className={styles.linkItemMob}>Download</div>
            </Link>
            <Link target="_blank" to="https://discord.gg/39hEFVAp">
              <div className={styles.linkItemMob}>Discord</div>
            </Link>
            <Link to={getRoute(routes.ACCOUNT)}>
              <div className={styles.linkItemMob}>Account</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
