import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Loader } from '../components/Loader';
import { getIsFetching, getLoaded, getToken } from '../store/slices/authSlice';
import { Main } from './Main';
import { Download } from './Download';
import { NotFound } from './NotFound';
import { routes } from '../consts/routes';
import { LogIn } from './Auth/LogIn/LogIn';
import { SignUp } from './Auth/SignUp/SignUp';
import { RestorePassword } from './Auth/RestorePassword/RestorePassword';
import { ColonyWar } from './Rankings/ColonyWar';
import { Aliiances } from './Rankings/Aliiances';
import { Factions } from './Rankings/Factions';
import { PvP } from './Rankings/PvP';
import { TopUp } from './Account/TopUp/TopUp';
import { Settings } from './Account/Settings/Settings';
import { SetNewPassword } from './Account/SetNewPassword/SetNewPassword';
import { PaymentHistory } from './Account/PaymentHistory/PaymentHistory';
import styles from './style.module.scss';

const Redirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/');
  }, [])
  return <></>;
}

export const Pages = () => {
  const token = useSelector(getToken);
  const loaded = !useSelector(getIsFetching);
  // if (!loaded) {
  //   return <Loader className={styles.loader} />;
  // }
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Routes>
          {/* Main menu */}
          <Route index element={<Main />} errorElement={<NotFound />} />
          <Route path={routes.DOWNLOAD} element={<Download />} />
        
          {/* Rankings menu */}
          <Route path={routes.colonyWar} element={<ColonyWar />} />
          <Route path={routes.alliances} element={<Aliiances />} />
          <Route path={routes.factions} element={<Factions />} />
          <Route path={routes.pvpLadder} element={<PvP />} />

          {/* Auth menu */}
          <Route path={routes.logIn} element={<LogIn />} />
          <Route path={routes.signUp} element={<SignUp />} />
          <Route path={routes.restorePassword} element={<RestorePassword />} />

          {/* Account menu */}
          <Route path={routes.settings} element={token ? <Settings /> : <Redirect />} />
          <Route path={routes.topUp} element={token ? <TopUp /> : <Redirect />} />
          <Route path={routes.paymentHistory} element={token ? <PaymentHistory /> : <Redirect />} />
          
          <Route
            path="recover-password/:emailCode"
            element={<SetNewPassword />}
            errorElement={<NotFound />}
          />

          {/* Not found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
};
