import { put, takeLatest } from 'redux-saga/effects';
import { mainAPI } from '../../../api/mainAPI';
import {
  coloniesReq,
  coloniesError,
  coloniesResponse,
  ladderReq,
  ladderResponse,
  ladderError,
  electionsReq,
  electionsError,
  electionsResponse,
  killCountReq,
  killCountError,
  killCountResponse,
  factionReq,
  factionError,
  factionResponse,
  downloadLinkResponse,
  downloadLinkError,
  downloadLinkReq
} from '../../slices/generalSlice';

export function* coloniesSaga() {
  try {
    // @ts-ignore
    const data = yield mainAPI.getСolonies();
    if (data.data) {
      yield put(coloniesResponse(data.data));
    } else {
      yield put(coloniesError());
    }
  } catch (e) {
    yield put(coloniesError());
  }
}

export function* ladderSaga() {
  try {
    // @ts-ignore
    const data = yield mainAPI.getLadder();
    if (data.data) {
      yield put(ladderResponse(data.data));
    } else {
      yield put(ladderError());
    }
  } catch (e) {
    yield put(ladderError());
  }
}

export function* electionsSaga() {
  try {
    // @ts-ignore
    const data = yield mainAPI.getElections();
    if (data.data) {
      yield put(electionsResponse(data.data));
    } else {
      yield put(electionsError());
    }
  } catch (e) {
    yield put(electionsError());
  }
}

export function* killCountSaga() {
  try {
    // @ts-ignore
    const data = yield mainAPI.getTopKillcount();
    if (data.data) {
      yield put(killCountResponse(data.data));
    } else {
      yield put(killCountError());
    }
  } catch (e) {
    yield put(killCountError());
  }
}

export function* factionSaga() {
  try {
    // @ts-ignore
    const data = yield mainAPI.getTopFactions();
    if (data.data) {
      yield put(factionResponse(data.data));
    } else {
      yield put(factionError());
    }
  } catch (e) {
    yield put(factionError());
  }
}

export function* downloadLinkSaga() {
  try {
    // @ts-ignore
    const data = yield mainAPI.getDownloadLink();
    if (data.data) {
      yield put(downloadLinkResponse(data.data));
    } else {
      yield put(downloadLinkError());
    }
  } catch (e) {
    yield put(downloadLinkError());
  }
}

export function* generalSagaWatcher() {
  yield takeLatest(coloniesReq, coloniesSaga);
  yield takeLatest(ladderReq, ladderSaga);
  yield takeLatest(electionsReq, electionsSaga);
  yield takeLatest(killCountReq, killCountSaga);
  yield takeLatest(factionReq, factionSaga);
  yield takeLatest(downloadLinkReq, downloadLinkSaga);
}
