import React, { HTMLInputAutoCompleteAttribute } from 'react';
import cx from 'classnames';
import Tooltip from '../Tooltip';
import styles from './textAdapter.module.scss';

interface ITextAdapterProps {
  input: any;
  meta: any;
  customStyles: Record<string, string>;
  helper: string;
  label: string;
  autoComplete: HTMLInputAutoCompleteAttribute;
}

export const TextAdapter = ({ input, meta, customStyles, helper, label, autoComplete }: ITextAdapterProps) => {
  return (
    <div className={cx(styles.container, customStyles?.container)}>
      <label className={cx(styles.label, customStyles?.label)} htmlFor={input.name}>
        {label || input.name}
      </label>
      <div className={cx(styles.inputWrap, customStyles?.inputWrap)}>
        <input
          {...input}
          className={cx(styles.input, customStyles?.input)}
          autoComplete={autoComplete}
        />
        {meta.touched && meta.error && (
          <div className={cx(styles.fieldErrorContainer, customStyles?.fieldErrorContainer)}>
            <div className={styles.fieldError}>{meta.error}</div>
            {helper && <Tooltip text={helper} />}
          </div>
        )}
      </div>
    </div>
  )
};
