import React, { useEffect } from 'react';
import { Button } from '../../components/Button';
import imgLeft from './img/left.png';
import imgLeft2x from './img/left_2x.png';
import imgRight from './img/right.png';
import imgRight2x from './img/right_2x.png';
import styles from './downloadPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { downloadLinkReq, getDownloadLink } from '../../store/slices/generalSlice';
import { mainAPI } from '../../api/mainAPI';

export const Download = () => {
  const dispatch = useDispatch();
  const link = useSelector(getDownloadLink);

  useEffect(() => {
    dispatch(downloadLinkReq());
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>Client Download</div>
        </div>
        <div className={styles.buttonsWrap}>
        <Button className={styles.btn} onClick={()=> {
          window.location.href = mainAPI.getAdditionalManualLink();
        }}>Manual</Button>
        <Button className={styles.btn} onClick={()=> {
          window.location.href = mainAPI.getTorrentLink();
        }}>Torrent</Button>
      </div>
      </div>

      <img className={styles.imgLeft} src={imgLeft} srcSet={`${imgLeft2x} 2x`}  alt="left" />
      <img className={styles.imgRight} src={imgRight} srcSet={`${imgRight2x} 2x`}  alt="right" />
    </div>
  )
}
