import { useState, useEffect } from 'react';
import { debounce } from '../helpers';

export const useVisibleState = (scrollbar: HTMLDivElement | null) => {
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);

  useEffect(() => {
    const withDebounce = debounce(500);
    const handler = () => withDebounce(setVisibleState);

    if (scrollbar) {
      window.addEventListener('resize', handler);
      setVisibleState();
    }
    return () => {
      if (scrollbar) window.removeEventListener('resize', handler);
    };
  }, [scrollbar]);

  const setVisibleState = () => {
    if (scrollbar) {
      const { clientWidth, scrollWidth, scrollLeft } = scrollbar;
      if (scrollLeft > 0) setVisibleLeft(true);
      else setVisibleLeft(false);
      if (Math.ceil(scrollLeft + clientWidth) >= scrollWidth - 10)
        setVisibleRight(false);
      else setVisibleRight(true);
    }
  };

  return { visibleLeft, visibleRight, setVisibleState };
};
