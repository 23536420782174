import { combineReducers } from '@reduxjs/toolkit';
import auth from './slices/authSlice';
import general from './slices/generalSlice';
import user from './slices/userSlice';

const RootReducer = combineReducers({
  auth,
  user,
  general
});

export type RootState = ReturnType<typeof RootReducer>;

export default RootReducer;
