import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { transactionHistoryReq, getTransactionMap, getLoadedPages } from '../../../store/slices/userSlice';
import { Table } from '../../../components/Table';
import imgRight from './img/cath.png';
import imgRight2x from './img/cath_2x.png';
import arrow from './img/arrow.svg';
import styles from './paymentHistory.module.scss';
import { useNavigate } from 'react-router-dom';
import { getRoute } from '../../../helpers/getRoute';
import { routes } from '../../../consts/routes';
import { Loader } from '../../../components/Loader';

const headData = [
  {
    width: '15%',
    title: 'Order',
    textAlign: 'center',
    keyInList: 'id',
    defaultValue: '-'
  },
  {
    width: '29%',
    title: 'Status',
    textAlign: 'center',
    keyInList: 'state',
    defaultValue: '-'
  },
  {
    width: '28%',
    title: 'Date',
    textAlign: 'center',
    keyInList: 'createDatetime',
    defaultValue: '0'
  },
  {
    width: '28%',
    title: 'Coins',
    textAlign: 'center',
    keyInList: 'GCC',
    defaultValue: '0'
  }
];

export const PaymentHistory = () => {
  const dispatch = useDispatch();
  const transactionMap = useSelector(getTransactionMap);
  const loadedPages = useSelector(getLoadedPages);
  const navigate = useNavigate();
  const countItemsPerPage = 8;

  const [currentPage, setCurrentPage] = useState(1);
  const transactions = Object.values(transactionMap).sort((a, b) => Number(b.id) - Number(a.id));
  const transactionData = transactions.slice((currentPage - 1) * countItemsPerPage, currentPage * countItemsPerPage);
  const transactionDataNext = transactions.slice((currentPage) * countItemsPerPage, (currentPage + 1) * countItemsPerPage);

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage >= 2 ? currentPage - 1 : currentPage);
  const isFinalPage = !!transactionMap[1] || (loadedPages == 1 && Object.keys(transactionMap).length == 0);

  const statusMap = { 
    created: 'Pending', 
    error: 'Error', 
    success: 'Success' 
  };

  const statusColorMap = {
    created: '#D6B235', 
    error: '#E35353', 
    success: ''
  }

  const getStatusText = (status: string) => {
    return statusMap[status as keyof typeof statusMap] || (status[0].toUpperCase() + status.slice(1));
  }

  const getStatusColor = (status: string) => {
    return statusColorMap[status as keyof typeof statusColorMap] || '';
  }

  useEffect(() => {
    if (!isFinalPage && (Object.keys(transactionMap).length < currentPage * countItemsPerPage + 1) && currentPage != 1) {
      dispatch(transactionHistoryReq(loadedPages + 1));
    }
  }, [currentPage, transactionMap, loadedPages]);
  
  useEffect(() => {
    if(currentPage == 1) {
      dispatch(transactionHistoryReq(1));
    } 
  }, [currentPage]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.block}>
          <div className={styles.titleWrap}>
            <div className={styles.title}>Payment history</div>
          </div>
          <div className={styles.gradientWrap}>
            <div className={styles.content}>
              {!transactionData.length ? (  
                !loadedPages ? <Loader /> : 
                <div className={styles.noHistoryWrap}>
                  <p className={styles.noHistoryInfo}>Here will be your payment history. To perform a transaction, please, follow the link below.</p>
                  <button className={styles.noHistoryBtn} onClick={() => {
                    navigate(getRoute(routes.topUp))
                  }}>Top up</button>
                </div>) :
              <>
                <Table
                  list={transactionData?.map(it => ({
                    ...it, createDatetime: new Date(it.createDatetime).toLocaleDateString(),
                    state: <span style={{ color: getStatusColor(it.state) }}>
                      {getStatusText(it.state)}
                    </span>
                  })) || []}
                  headData={headData}
                  className={styles.tableContainer}
                  customStyles={styles}
                />
                {transactions.length > countItemsPerPage ?
                  (<div className={styles.tablePagination}>
                    <button className={[styles.btnPrev, currentPage !== 1 ? '' : styles.hidden].join(' ')} onClick={prevPage}>
                      <img className={styles.btnImage} src={arrow} alt="arrow left" />
                    </button>
                    <span className={styles.currentPage}>{currentPage}</span>
                    <button className={[styles.btnNext, transactionDataNext.length > 0 ? '' : styles.hidden].join(' ')} onClick={nextPage}>
                      <img className={styles.btnImage} src={arrow} alt="arrow right" />
                    </button>
                  </div>) : ''}
              </>
              }
            </div>
          </div>
        </div>
      </div>
      <img className={styles.rightImg} src={imgRight} srcSet={`${imgRight2x} 2x`} alt="cath" />
    </div>
  )
}