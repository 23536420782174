import React from 'react';
import slide1Image from './img/slide1Image.png';
import slide1Image2x from './img/slide1Image_2x.png';
import slide2Image from './img/slide2Image.png';
import slide2Image2x from './img/slide2Image_2x.png';
import slide3Image from './img/slide3Image.png';
import slide3Image2x from './img/slide3Image_2x.png';
import styles from './slideContent.module.scss';

export const SlideContent1 = () => {
  return (
    <div className={styles.slideContent}>
      <div className={styles.left}>
        <div className={styles.imageWrap}>
          <img className={styles.slideImage} src={slide1Image} srcSet={`${slide1Image2x} 2x`} alt="" />
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.slideTitleWrap}>
          <div className={styles.slideTitle}>Server Features</div>
        </div>
        <div className={styles.gradientWrap}>
          <div className={styles.textContent}>
            <ul className={styles.featuresList}>
              <li className={styles.featuresItem}>Start from v.2.0 and regular updates</li>
              <li className={styles.featuresItem}>Classic exp and drop rates</li>
              <li className={styles.featuresItem}>A lot of forgotten GE mechanics</li>
              <li className={styles.featuresItem}>Old characters and stances meta</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export const SlideContent2 = () => {
  return (
    <div className={styles.slideContent}>
      <div className={styles.left}>
        <div className={styles.imageWrap}>
          <img className={styles.slideImage} src={slide2Image} srcSet={`${slide2Image2x} 2x`} alt="" />
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.slideTitleWrap}>
          <div className={styles.slideTitle}>PvE Content</div>
        </div>
        <div className={styles.gradientWrap}>
          <div className={styles.textContent}>
            <ul className={styles.featuresList}>
              <li className={styles.featuresItem}>Many activities instead of boring dailies</li>
              <li className={styles.featuresItem}>Hardcore raids for coordinated teams</li>
              <li className={styles.featuresItem}>Lots of field bosses</li>
              <li className={styles.featuresItem}>Challenging quests </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export const SlideContent3 = () => {
  return (
    <div className={styles.slideContent}>
      <div className={styles.left}>
        <div className={styles.imageWrap}>
          <img className={styles.slideImage} src={slide3Image} srcSet={`${slide3Image2x} 2x`} alt="" />
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.slideTitleWrap}>
          <div className={styles.slideTitle}>PvP features</div>
        </div>
        <div className={styles.gradientWrap}>
          <div className={styles.textContent}>
            <ul className={styles.featuresList}>
              <li className={styles.featuresItem}>Equipment matters more than characters</li>
              <li className={styles.featuresItem}>21 Colonies with classic CW system</li>
              <li className={styles.featuresItem}>Original PvP balance</li>
              <li className={styles.featuresItem}>Old burlon system </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}