import axios from 'axios';

const host = 'https://ge-classique.com';
// const username = 'demo';
// const password = 'itisDEMOuser';
export const instance = axios.create({
  baseURL: `${host}/api/v1`,
  withCredentials: false,
  // headers: {
  //   Authorization: `Basic ${btoa(`${username}:${password}`)}`
  // }
});

export const getImgLink = (src: string) => `${host}/images/${src}`;
