import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components/Button';
import { Loader } from '../../../components/Loader';
import { TextAdapter } from '../../../components/TextAdapter';
import { getToken, regResponse } from '../../../store/slices/authSlice';
import { TFields, fieldsConfig } from '../configs';
import stylesAuth from '../authPage.module.scss';
import stylesSignUp from './signup.module.scss';
const styles = { ...stylesAuth, ...stylesSignUp };
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../consts/routes';
import { getRoute } from '../../../helpers/getRoute';
import { errorsMessages } from '../../../translates/errors';
import { useModal } from '../../../components/Modal';
import { MessagePopup } from '../../../components/MessagePopup/MessagePopup';
import { mainAPI } from '../../../api/mainAPI';
import { serverMessages } from '../../../translates/serverMessages';
import imgLeft from '../img/left.png';
import imgRight from '../img/right.png';
import imgLeft2x from '../img/left_2x.png';
import imgRight2x from '../img/right_2x.png';
import { composeValidators, passwordValidate, required, validateEmail, validateLogin } from '../../../helpers/validators';

export const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isShow, Modal, onOpen, onClose } = useModal();
  const token = useSelector(getToken);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [formError, setFormError] = useState('');
  const [isSendedForm, setIsSendedForm] = useState(false);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [serverErrorShow, setServerErrorShow] = useState('');

  useEffect(() => {
    if ((token && isModalClosed) || (token && !isSendedForm)) {
      navigate(getRoute(routes.HOME))
    }
  }, [token, isModalClosed, isSendedForm]);

  const fields: TFields[] = fieldsConfig.reg;
  const onSubmit = (v: any) => {
    sendRequest(v.username, v.password, v.email);
  };

  const sendRequest = async (username: string, password: string, email: string) => {
    setIsFetching(true);
    setServerErrorShow('');
    setError('');
    setMessage('');
    try {
      const data = await mainAPI.registration({ username, password, email });
      if (data) {
        localStorage.setItem('Token', data.data.token);
        localStorage.setItem('MemberId', data.data.memberId);
        dispatch(regResponse(data.data));
        setIsFetching(false);
        setMessage(serverMessages.accountCreated);
        onOpen();
      }
    } catch (e) {
      setIsFetching(false);
      const errorMap: Record<string, string> = { 'Email already in use': serverMessages.alreadyInUse };
      const currentError = errorMap[e?.response?.data?.data?.error];
      if (currentError) {
        setServerErrorShow(currentError);
      } else {
        setError(serverMessages.unknownError);
        onOpen();
      }     
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>Sign up</div>
        </div>

        {isFetching && <Loader />}
        <div className={styles.gradientWrap}>
          <div className={[styles.formContent, styles.formContentSignUp].join(' ')}>
            <Form
              validate={(values: any) => {
                const errors: any = {};
                if (values['confirm-password'] != values['password']) {
                  errors['confirm-password'] = errorsMessages.passwordsNotMatch;
                }
                errors.username = validateLogin(values.username);
                errors.email = validateEmail(values.email);
                errors.password = composeValidators(passwordValidate, required)(values.password);
                const priorityError = errors.username || errors.email || errors.password || errors['confirm-password'] || '';
                setFormError(priorityError);
                return errors;
              }}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => {
                return (
                  <div className={styles.formContainer}>
                    <form className={[styles.form, styles.formSignUp].join(' ')} onSubmit={handleSubmit}>
                      {fields.map(({ name, label, type, validate, helper }) => (
                        <div key={name} className={styles.fieldContainer}>
                          <Field
                            type={type}
                            component={TextAdapter}
                            validate={validate}
                            name={name}
                            helper={helper}
                            label={label}
                            customStyles={styles}
                          />
                        </div>
                      ))}

                    </form>
                    <p className={styles.note}>
                      {(formError && isSendedForm) ? formError : 'Tip: Do not use passwords previously used on other servers'}
                    </p>
                    <div className={[styles.buttonsWrap, styles.buttonsWrapSignUp].join(' ')}>
                      <Button className={[styles.regBtn, styles.regBtnSignUp].join(' ')} onClick={() => {
                        handleSubmit();
                        setIsSendedForm(true);
                      }}>
                        Create Account
                      </Button>
                    </div>
                    {serverErrorShow && <p className={styles.error}>{serverErrorShow}</p>}
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
      <img className={styles.imgLeft} src={imgLeft} srcSet={`${imgLeft2x} 2x`} alt="left" />
      <img className={styles.imgRight} src={imgRight} srcSet={`${imgRight2x} 2x`} alt="right" />
      <Modal isShow={isShow}>
        <MessagePopup title={error ? 'Error' : 'Success'} message={error || message} handler={() => {
          onClose();
          setIsModalClosed(true);
        }} />
      </Modal>
    </div>
  );
};
