import React from 'react';
import cn from 'classnames';
import { Loader } from '../Loader';
import styles from './table.module.scss';

type TProps = {
  headData: {
    width: string;
    title: string;
    textAlign: string;
    keyInList: string;
    defaultValue?: string;
  }[];
  list?: any[];
  className?: string;
  customStyles?: Record<string, string>;
}

const getValue = (item: any, keyInList: string, defaultValue: any) =>
  item[keyInList] || defaultValue || '-';

export const Table = ({ list, headData, className, customStyles }: TProps) => {
  if (!list /* || !list?.length */ ) {
    return (
      <div className={cn(styles.container, className)}>
        <Loader className={styles.loader} />
      </div>
    );
  }
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.column}>
        {headData.map(({ width, textAlign, title }) => (
          // @ts-ignore
          <div className={customStyles?.tableHead} key={title} style={{ width, textAlign }}>
            {title}
          </div>
        ))}
      </div>
      <div className={styles.scrollBlock}>
        {list.map((item, index) => (
          <div key={index} className={styles.column}>
            {headData.map(({ width, textAlign, keyInList, defaultValue }) => (
              // @ts-ignore
              <div className={customStyles?.tableItem} key={keyInList} style={{ width, textAlign }}>
                {getValue(item, keyInList, defaultValue)}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
