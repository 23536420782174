import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { coloniesReq, getColonies, getLastSunday } from '../../../store/slices/generalSlice';
import colony from './colony.png';
import colony2x from './colony_2x.png';
import styles from './colonyWar.module.scss';
import { Loader } from '../../../components/Loader';
import { getIsFetching } from '../../../store/slices/userSlice';

export const ColonyWar = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(getIsFetching);
  const colonies = useSelector(getColonies);
  const lastSunday = useSelector(getLastSunday);
  useEffect(() => {
    dispatch(coloniesReq());
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.left}>
          <div className={styles.titleWrap}>
            <div className={styles.title}>Colony war results</div>
            <div className={styles.titleDate}>{lastSunday}</div>
          </div>
          {(!colonies) ? (
            <Loader />
          ) : (
            <div className={styles.gradientWrap}>
              <div className={styles.colonyContent}>
                {colonies.map(({ zoneName, partyName }) => (
                  <div key={zoneName + partyName} className={styles.item}>
                    <span className={styles.zoneName}>{zoneName}</span>
                    <span className={styles.partyName}>{partyName || '-'}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={styles.right}>
          <img className={styles.colonyImg} src={colony} srcSet={`${colony2x} 2x`} alt="colony" />
        </div>
      </div>
    </div>
  );
};
