import React from 'react';
import { PaymentOptionButton } from '../../types/paymentType';
import styles from './buttonPayment.module.scss';

interface IButtonPaymentProps {
  data: Array<PaymentOptionButton>;
  onPay: (option: PaymentOptionButton) => void;
}

export const ButtonPayment = ({ data, onPay }: IButtonPaymentProps) => {
  return (
    <div className={styles.paymentWrapper}>
      {data.map((item, index) => (
        <button key={index} className={styles.payBtn} onClick={() => {
          onPay(item);
        }}>
          <span></span>
          <span className={styles.payBtnTitle}>
            <span className={styles.payBtnTokenAmount}>{item.tokenAmount}</span>
            <span className={styles.payBtnCoins}>Coins</span>
            <span className={styles.payBtnAmountCurrency}>
              <span className={styles.payBtnAmount}>{item.amount}</span>
              <span className={styles.payBtnCurrency}>{item.currency}</span>
            </span>
          </span>
          <span></span>
        </button>
      ))}
    </div >
  )
}
