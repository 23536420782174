import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '../../../components/Table';
import { ladderReq, getLadder } from '../../../store/slices/generalSlice';
import adelina from './img/adelina.png';
import adelina2x from './img/adelina_2x.png';
import grace from './img/grace.png';
import grace2x from './img/grace_2x.png';
import styles from './pvp.module.scss';

const headData = [
  {
    width: '20%',
    title: 'Rank',
    textAlign: 'center',
    keyInList: 'position',
    defaultValue: '-'
  },
  {
    width: '40%',
    title: 'Family',
    textAlign: 'center',
    keyInList: 'member',
    defaultValue: '-'
  },
  {
    width: '20%',
    title: 'Wins',
    textAlign: 'center',
    keyInList: 'duelsWon',
    defaultValue: '0'
  },
  {
    width: '20%',
    title: 'Looses',
    textAlign: 'center',
    keyInList: 'duelsLost',
    defaultValue: '0'
  }
];
export const PvP = () => {
  const dispatch = useDispatch();
  const { ladderData } = useSelector(getLadder);

  useEffect(() => {
    dispatch(ladderReq());
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.block}>
          <div className={styles.titleWrap}>
            <div className={styles.title}>PvP Ladder</div>
          </div>
          <div className={styles.gradientWrap}>
            <div className={styles.content}>
              <Table
                list={ladderData?.map(it => ({...it, position: it.position + '.'}))}
                headData={headData}
                className={styles.tableContainer}
                customStyles={styles}
              />
            </div>
          </div>
        </div>
      </div>
      <img className={styles.leftImg} src={adelina} srcSet={`${adelina2x} 2x`} alt="adelina" />
      <img className={styles.rightImg} src={grace} srcSet={`${grace2x} 2x`} alt="grace" />
    </div>
  );
};
