import { put, takeLatest } from 'redux-saga/effects';
import { mainAPI } from '../../../api/mainAPI';
import {
  balanceError,
  balanceReq,
  balanceResponse,
  shopReq,
  shopResponse,
  shopError,
  buyShopReq,
  buyShopError,
  buyShopResponse,
  payReq,
  payResponse,
  payError,
  transactionHistoryReq,
  transactionHistoryResponse,
  transactionHistoryError
} from '../../slices/userSlice';

export function* balanceSaga() {
  try {
    // @ts-ignore
    const data = yield mainAPI.getBalance();
    if (data.data) {
      yield put(balanceResponse(data.data));
    } else {
      yield put(balanceError());
    }
  } catch (e) {
    // @ts-ignore
    if (e.response.status === 401) {
      localStorage.removeItem('Token');
    }
    yield put(balanceError());
  }
}

export function* shopSaga() {
  try {
    // @ts-ignore
    const data = yield mainAPI.getShop();
    if (data.data) {
      yield put(shopResponse(data.data));
    } else {
      yield put(shopError());
    }
  } catch (e) {
    yield put(shopError());
  }
}
export function* paySaga({ payload }: { payload: string }) {
  try {
    // @ts-ignore
    const data = yield mainAPI.pay(payload);
    console.log(data);
    // логика не реализована
    // if (data.data) {
    //   yield put(payResponse(data.data));
    // } else {
    //   yield put(payError());
    // }
  } catch (e) {
    // yield put(payError());
  }
}

export function* buyShopSaga({
  payload
}: {
  payload: { itemId: string; amount: number }[];
}) {
  try {
    // @ts-ignore
    const data = yield mainAPI.buyInShop(payload);
    if (data.data) {
      yield put(buyShopResponse(data.data));
    } else {
      yield put(buyShopError());
    }
  } catch (e) {
    yield put(buyShopError());
  }
}

export function* transactionHistorySaga({ payload }: { payload: number }) {
  try {
    // @ts-ignore
    const data = yield mainAPI.getTransactionHistory(payload);
    if (data.data) {
      yield put(transactionHistoryResponse({data: data.data, page: payload}));
    } else {
      yield put(transactionHistoryError());
    }
  } catch (e) {
    // @ts-ignore
    if (e.response.status === 401) {
      localStorage.removeItem('Token');
    }
    yield put(transactionHistoryError());
  }
}

export function* userSagaWatcher() {
  // @ts-ignore
  yield takeLatest(balanceReq, balanceSaga);
  // @ts-ignore
  yield takeLatest(shopReq, shopSaga);
  // @ts-ignore
  yield takeLatest(payReq, paySaga);
  // @ts-ignore
  yield takeLatest(buyShopReq, buyShopSaga);
  // @ts-ignore
  yield takeLatest(transactionHistoryReq, transactionHistorySaga);
}
