import React, { PropsWithChildren, useEffect, useState } from 'react';
import cn from 'classnames';
import arrow from './arrow.svg';
import dotSmall from './dotSmall.svg';
import { ScrollDirection } from './configs/consts';
import dot from './dot.svg';
import dotLarge from './dotLarge.svg';
import { getScrollLeftValueSlideByItem } from './helpers';
import { useReactiveRef } from './hooks/useReactiveRef';
import { useVisibleState } from './hooks/useVisibleState';
import { TScrollOptions } from './types';

import styles from './scrollBlock.module.scss';

const defaultScrollOptions = {
  slideByWith: 0.5
};

export type TScrollBlockApi = {
  visibleLeft: boolean;
  visibleRight: boolean;
  scrollOnClick(direction: ScrollDirection): void;
  scrollbar: HTMLDivElement | null;
};

type TProps = {
  className?: string;
  scrollOptions?: TScrollOptions;
  setApi?(api: TScrollBlockApi): void;
};

export const ScrollBlock = ({
  children,
  className,
  setApi,
  scrollOptions = defaultScrollOptions
}: PropsWithChildren<TProps>) => {
  const [position, setPosition] = useState(0);
  const { element: scrollbar, setRef } = useReactiveRef<HTMLDivElement>();
  const { visibleLeft, visibleRight, setVisibleState } = useVisibleState(
    scrollbar
  );

  const scrollOnClick = (
    direction: ScrollDirection,
    custom?: TScrollOptions
  ) => {
    if (!scrollbar) return;

    const { scrollLeft, toScrollElementIndex } = getScrollLeftValueSlideByItem(
      direction,
      scrollbar,
      custom || scrollOptions
    );

    if (
      typeof scrollLeft === 'number' &&
      typeof toScrollElementIndex === 'number'
    ) {
      scrollbar.scrollTo({ left: scrollLeft, behavior: 'smooth' });
      setPosition(scrollLeft > 0 ? toScrollElementIndex : 0);
    }
  };

  useEffect(() => {
    if (setApi) {
      setApi({
        visibleLeft,
        visibleRight,
        scrollOnClick,
        scrollbar
      });
    }
  }, [visibleRight, visibleLeft, scrollbar]);

  const onLeft = () => {
    scrollOnClick(ScrollDirection.left);
  };
  const onRight = () => {
    scrollOnClick(ScrollDirection.right);
  };
  const onDotClick = (nextSlide: number) => {
    const diff = nextSlide - position;
    if (diff === 0) {
      return;
    }
    if (diff < 0) {
      setPosition(nextSlide);
      scrollOnClick(ScrollDirection.left, { slideByItem: Math.abs(diff) });
    } else {
      setPosition(nextSlide);
      scrollOnClick(ScrollDirection.right, { slideByItem: diff });
    }
  };

  return (
    <div className={styles.container}>
      <div
        onScroll={setVisibleState}
        ref={setRef}
        className={cn(styles.scrollContent, className)}>
        {children}
      </div>
      <div className={styles.control}>
        <button
          disabled={!visibleLeft}
          type="button"
          onClick={onLeft}
          className={styles.btnLeft}>
          <img src={arrow} alt="arrow" />
        </button>
        <div className={styles.dots}>
          <div className={styles.line} >
            <img className={styles.dotSmallLeft} src={dotSmall} alt="" />
            <img className={styles.dotSmallRight} src={dotSmall} alt="" />
          </div>
          {/* @ts-ignore */}
          {Array.from(children).map((item, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div key={index} onClick={() => onDotClick(index)} className={[styles.dot, position === index ? styles.dotActive : ''].join(' ')}>
              <img className={styles.dotLargeImg} src={dotLarge} alt="dot" />
              <img className={styles.dotImg} src={dot} alt="dot" />
            </div>
          ))}
        </div>
        <button
          type="button"
          disabled={position === 2}
          onClick={onRight}
          className={styles.btnRight}>
          <img src={arrow} alt="arrow" />
        </button>
      </div>
    </div>
  );
};
