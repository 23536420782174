import React from 'react';
import { Link } from 'react-router-dom';
import { PaymentOptionLink } from '../../types/paymentType';
import styles from './linkPayment.module.scss';

interface ILinkPaymentProps {
  data: PaymentOptionLink
}

export const LinkPayment = ({ data }: ILinkPaymentProps) => {
  return (
    <div className={styles.paymentWrapper}>
      <p className={styles.description}>{data.description}</p>
      <Link to={data.link} className={styles.ticketLink}>
        <span></span>
        <span className={styles.ticketLinkTitle}>Top Up</span>
        <span></span>
      </Link>
    </div>
  )
}
