import { errorsMessages } from "../translates/errors";

export const composeValidators = (...validators: any) => (v?: string) => {
  const res = validators.reduce((error: any, validator: any) => {
    return error || validator(v);
  }, undefined);
  return res;
};
export const passwordValidate = (v?: string) => {
  if(!v || v.length < 6) return errorsMessages.passwordTooShort;
  const regex = /^[a-zA-Z0-9_]{6,16}$/;

  if (v && regex.test(v)) {
    return undefined;
  }
  return errorsMessages.passwordIncorrect;
};
export const required = (v?: string) => {
  if (v?.length) {
    return undefined;
  }
  return 'field required';
};

export const samePasswords = (secondPass: string) => (pass: string) => {
  if (!(secondPass && pass)) {
    return undefined;
  }
  console.log(secondPass, pass);
  return secondPass === pass ? undefined : 'Passwords are different';
};

export const checkSum = (v?: string) => {
  const arr = [5, 10, 20, 30, 40, 50, 70, 100, 500];
  if (v?.length && arr.includes(Number(v))) {
    return undefined;
  }
  return 'not valid sum';
};

export const maxLength = (max: number) => (value = '') => {
  const errMsg = `max length ${max} symbol`;
  return value?.length > max ? errMsg : undefined;
};

export const validateLogin = (name: string) => {
  if(!name) return errorsMessages.usernameEmpty;
  if(name.length > 12) return errorsMessages.usernameIsTooLong;
}

export const validateEmail = (email: string) => {
  if(email && email.length > 256) return errorsMessages.emailIsTooLong;
  const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if(!reg.test(email)) {
    return errorsMessages.emailEmpty;
  }
}
