import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import styles from './tooltip.module.scss';

const Tooltip = ({ text }: { text: string }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.trigger}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <InfoIcon />
      </div>
      {showTooltip && <div className={styles.tooltip}>{text}</div>}
    </div>
  );
};

export default Tooltip;
