import React from 'react';
import notFound from './notFound.png';
import notFound2x from './notFound_2x.png';
import styles from './notFoundPage.module.scss';

export const NotFound = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>404 Not Found</div>
        </div>
        <img className={styles.notFoundImg} src={notFound} srcSet={`${notFound2x} 2x`} alt="notFound" />
      </div>
    </div>
  );
};
