import React, { ReactNode } from 'react';
import cn from 'classnames';
import marker from './marker.png';
import styles from './slide.module.scss';

interface ISlideProps {
  className?: string;
  children?: ReactNode;
}

export const Slide = ({className, children }: ISlideProps) => {
  return (
    <div className={cn(styles.slide, className, children)}>
      {children}      
    </div>
  );
};
