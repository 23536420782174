import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../../../consts/routes';
import { getRoute } from '../../../../helpers/getRoute';
import { balanceReq, getBalance, getUsername } from '../../../../store/slices/userSlice';
import headerStyles from '../../header.module.scss';
import authStyles from './authBlock.module.scss';
import { logOut } from '../../../../store/slices/authSlice';
const styles = { ...headerStyles, ...authStyles };

export const AuthBlock = () => {
  const [isUsernameShow, setIsUsernameShow] = useState(false);
  const dispatch = useDispatch();
  const balance = useSelector(getBalance);
  const username = useSelector(getUsername);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(balanceReq());
  }, []);

  const usernameButton = useRef<HTMLButtonElement>();
  useEffect(() => {
    if (!isUsernameShow) {
      return;
    }
    const close = (evt: MouseEvent) => {
      if (evt.target != usernameButton.current) {
        setIsUsernameShow(false);
        window.removeEventListener('click', close);
      }
    }
    window.addEventListener('click', close, true);

    return () => window.removeEventListener('click', close);
  }, [isUsernameShow]);

  return (
    <div className={styles.container}>
      <div className={styles.balance}>C {balance} </div>
   
      <div className={styles.linkItemDrop}>
        <button ref={usernameButton} className={[styles.linkItem, styles.dropDownButton, isUsernameShow ? styles.dropDownButtonOpen : ''].join(' ')}
          onClick={() => setIsUsernameShow(last => !last)}>{username || 'Account'}</button>
        <div className={[styles.dropDownGrid, isUsernameShow ? styles.dropDownGridOpen : ''].join(' ')}>
          <div className={styles.dropDownList}>
            <Link to={getRoute(routes.topUp)}>
              <div className={[styles.linkItem, styles.dropDownListItem].join(' ')}>Top up</div>
            </Link>
            <Link to={getRoute(routes.paymentHistory)}>
              <div className={[styles.linkItem, styles.dropDownListItem].join(' ')}>Payment history</div>
            </Link>
            <Link to={getRoute(routes.settings)}>
              <div className={[styles.linkItem, styles.dropDownListItem].join(' ')}>Settings</div>
            </Link>
            <button className={styles.buttonExit} onClick={() => {
              dispatch(logOut());
              localStorage.removeItem('Token');
              navigate(getRoute(routes.HOME));
            }}>
              <div className={[styles.linkItem, styles.dropDownListItem].join(' ')}>Exit</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
