import React, { useEffect, useState } from 'react';
import styles from './tabs.module.scss';
import { LinkPayment } from '../LinkPayment';
import { ButtonPayment } from '../ButtonPayment';
import { PaymentOptionButton } from '../../types/paymentType';

interface ITabsProps {
  tabsData: any;
  activeTab: string;
  onPay: (options: PaymentOptionButton) => void;
  setActiveTab: (tabName: string) => void;
}

export const Tabs = ({ tabsData, activeTab, onPay, setActiveTab }: ITabsProps) => {
  return (
    <div className={styles.tabs}>
      <div className={styles.tabsTitles}>
        <ul className={styles.tabsTitlesList}>
          {Object.keys(tabsData).map(tabName => {
            return (
              <li key={tabName} className={[styles.tabsTitlesItem, activeTab == tabName ? styles.tabsTitlesItemActive : ''].join(' ')} >
                <button className={styles.tabsButton} onClick={() => {
                  setActiveTab(tabName);
                }}>{tabName[0] + tabName.slice(1).toLowerCase()}</button>
              </li>
            )
          })}
        </ul>
      </div>

      <div className={styles.gradientWrap}>
        <div className={styles.formContent}>
          {(() => {
            const currentTabData = tabsData[activeTab];
            if (Array.isArray(currentTabData)) {
              return (
                <div className={[styles.formContainer, styles.formContainerButtonPayment].join(' ')}>
                  <ButtonPayment data={currentTabData} onPay={(options) => {
                    onPay(options);
                  }} />
                </div>
              )
            }
            else if (currentTabData) {
              return (
                <div className={[styles.formContainer, styles.formContainerLinkPayment].join(' ')}>
                  <LinkPayment data={currentTabData} />
                </div>
              )
            }
            return '';
          })()}
        </div>
      </div>
    </div>
  )
}
