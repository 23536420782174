import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components/Button';
import { Loader } from '../../../components/Loader';
import { TextAdapter } from '../../../components/TextAdapter';
import {
  getError,
  getIsFetching,
  getMessage,
  getToken
} from '../../../store/slices/authSlice';
import {
  TFields,
  fieldsConfig,
  action,
  actionBtnText,
  headerText
} from '../configs';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../consts/routes';
import { getRoute } from '../../../helpers/getRoute';
import { useModal } from '../../../components/Modal';
import { MessagePopup } from '../../../components/MessagePopup/MessagePopup';
import { mainAPI } from '../../../api/mainAPI';
import { serverMessages } from '../../../translates/serverMessages';
import imgLeft from '../img/left.png';
import imgRight from '../img/right.png';
import imgLeft2x from '../img/left_2x.png';
import imgRight2x from '../img/right_2x.png';
import styles from '../authPage.module.scss';

export const RestorePassword = () => {
  const navigate = useNavigate();
  const token = useSelector(getToken);
  const { isShow, Modal, onOpen, onClose } = useModal();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (token) {
      navigate(getRoute(routes.HOME))
    }
  }, [token]);

  const fields: TFields[] = fieldsConfig.restore;
  const onSubmit = (v: any) => {
    sendRequest(v.email);
  };
  
  const sendRequest = async (email: string) => {
    setIsFetching(true);
    setError('');
    setMessage('');
    try {
      const data = await mainAPI.restorePassword({email});
      if (data) {
        setIsFetching(false);
        setMessage(serverMessages.checkEmail);
        onOpen();
      }
    } catch (e) {
      setIsFetching(false);
      const errorMap: Record<string, string> = {'Email not found': serverMessages.emailNotFound}
      setError(errorMap[e?.response?.data?.data?.error] || serverMessages.unknownError);
      onOpen();
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>Reset password</div>
        </div>

        {isFetching ? (
          <Loader />
        ) : (
          <div className={styles.gradientWrap}>
            <div className={[styles.formContent, styles.formContentRestore].join(' ')}>
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => {
                  return (
                    <div className={[styles.formContainer, styles.formContainerRestore].join(' ')}>
                      <form className={styles.form} onSubmit={handleSubmit}>
                        {fields.map(({ name, label, type, validate, helper }) => (
                          <div key={name} className={styles.fieldContainer}>
                            <Field
                              type={type}
                              component={TextAdapter}
                              validate={validate}
                              name={name}
                              helper={helper}
                              label={label}                              
                              customStyles={styles}
                            />
                          </div>
                        ))}
                      </form>
                      <div className={[styles.buttonsWrap, styles.buttonsWrapRestore].join(' ')}>
                        <Button className={[styles.regBtn, styles.regBtnLogin].join(' ')} onClick={() => {
                          navigate(getRoute(routes.logIn))
                        }}>
                          Back
                        </Button>
                        <Button className={[styles.regBtn, styles.regBtnLogin].join(' ')} onClick={handleSubmit}>
                          Reset
                        </Button>
                      </div>                    
                    </div>
                  );
                }}
              />
            </div>
          </div>
        )}
      </div>
      <img className={styles.imgLeft} src={imgLeft} srcSet={`${imgLeft2x} 2x`} alt="left" />
      <img className={styles.imgRight} src={imgRight} srcSet={`${imgRight2x} 2x`} alt="right" />
      <Modal isShow={isShow} onClose={onClose}>
        <MessagePopup title={error ? 'Error' : 'Success'} message={error || message} handler={onClose} />
      </Modal>
    </div>
  );
};
