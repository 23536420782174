import React from 'react';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomLoader = styled(CircularProgress)({
  color: '#EDBF9C'
});

export const Loader = ({ className }: { className?: string }) => {
  return <CustomLoader size={100} className={className} />;
};
