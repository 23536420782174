import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../components/Button';
import { TextAdapter } from '../../../../components/TextAdapter';
import { getError, getMessage } from '../../../../store/slices/authSlice';
import { getIsFetching } from '../../../../store/slices/userSlice';
import { TFields, action, changePasswordFields, emailCodeFields } from '../../../Auth/configs';
import { Loader } from '../../../../components/Loader';
import styles from './emailCode.module.scss';
import { mainAPI } from '../../../../api/mainAPI';
import { useModal } from '../../../../components/Modal';
import { MessagePopup } from '../../../../components/MessagePopup/MessagePopup';
import { useNavigate } from 'react-router-dom';
import { getRoute } from '../../../../helpers/getRoute';
import { routes } from '../../../../consts/routes';
import { serverMessages } from '../../../../translates/serverMessages';

interface IEmailCodeProps {
  oldPassword: string;
  newPassword: string;
  handler: () => void;
}

export const EmailCode = ({ oldPassword, newPassword, handler }: IEmailCodeProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const fields: TFields[] = emailCodeFields;
  const { isShow, Modal, onOpen, onClose } = useModal();

  const sendRequest = async (code: string) => {
    try {
      const data = await mainAPI.changePassword({ oldPassword, newPassword, code });
      if (data) {
        setMessage(serverMessages.passwordChanged);
      }
    } catch (e) {
      setError(serverMessages.errorChangePassword);
    }
  }

  useEffect(() => {
    if (message || error) {
      onOpen();
    }
  }, [message, error]);

  const onSubmit = (v: any) => {
    // dispatch(action.change(v));
    setMessage('');
    setError('');
    sendRequest(v.code);
  };
  
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>E-mail code</div>
        </div>
        {isFetching ? (
          <Loader />
        ) : (
          <>
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => {
                return (
                  <>
                    <div className={styles.gradientWrap}>
                      <div className={styles.formContent}>
                        <div className={styles.textContent}>
                          <p className={styles.textItem}>The password change code has been sent to your email.</p>
                          <p className={styles.textItem}>Please enter it in the field below to confirm the password change.</p>
                        </div>

                        <div className={styles.formContainer}>
                          <form className={styles.form} onSubmit={handleSubmit}>
                            {fields.map(({ name, label, type, validate, helper }) => (
                              <div key={name} className={styles.fieldContainer}>
                                <Field
                                  type={type}
                                  component={TextAdapter}
                                  validate={validate}
                                  name={name}
                                  helper={helper}
                                  label={label}                                  
                                  customStyles={styles}
                                />
                              </div>
                            ))}
                          </form>                          
                        </div>

                      </div>
                    </div>
                    <div className={styles.buttonsWrap}>
                      <Button className={styles.regBtn} onClick={() => { handler()}}>Back</Button>
                      <Button className={styles.regBtn} onClick={handleSubmit}>Apply</Button>
                    </div>
                  </>
                );
              }}
            />
          </>
        )}
      </div>
      <Modal isShow={isShow}>
        <MessagePopup title={error ? 'Error' : 'Success'} message={error || message} handler={() => {
          onClose();   
          if(!error) {
            handler();
            navigate(getRoute(routes.HOME));
          }     
        }} />
      </Modal>
    </div>
  )
}