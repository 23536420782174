import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Button } from '../../../components/Button';
import { TextAdapter } from '../../../components/TextAdapter';
import { TFields, changePasswordFields } from '../../Auth/configs';
import { Loader } from '../../../components/Loader';
import { EmailCode } from './EmailCode/EmailCode';
import imgRight from './jack.png';
import imgRight2x from './jack_2x.png';
import styles from './settings.module.scss';
import { useModal } from '../../../components/Modal';
import { mainAPI } from '../../../api/mainAPI';
import { MessagePopup } from '../../../components/MessagePopup/MessagePopup';

export const Settings = () => {
  const { isShow, Modal, onOpen, onClose } = useModal();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');
  const [errorCode, setErrorCode] = useState(0);
  const [message, setMessage] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const sendRequest = async () => {
    try {
      const data = await mainAPI.sendCode();
      if (data) {
        setMessage('Ok, check you mail');
      }
    } catch (e) {
      setError(e?.response?.data?.data?.error);
      setErrorCode(e?.response?.data?.code);
    }
  }

  useEffect(() => {
    if(message || error) {
      onOpen();
    }
  }, [message, error]);

  const fields: TFields[] = changePasswordFields;
  const onSubmit = (v: any) => {  
    setMessage('');
    setError('');
    setErrorCode(0);
    sendRequest();
    setOldPassword(v.oldPassword);
    setNewPassword(v.newPassword);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>Change password</div>
        </div>
        {isFetching ? (
          <Loader />
        ) : (
          <div className={styles.gradientWrap}>
            <div className={styles.formContent}>
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => {
                  return (
                    <div className={styles.formContainer}>
                      <form className={styles.form} onSubmit={handleSubmit}>
                        {fields.map(({ name, label, type, validate, helper }) => (
                          <div key={name} className={styles.fieldContainer}>
                            <Field
                              type={type}
                              component={TextAdapter}
                              validate={validate}
                              name={name}
                              helper={helper}
                              label={label}                             
                              customStyles={styles}
                            />
                          </div>
                        ))}
                      </form>
                      <div className={styles.buttonsWrap}>
                        <Button className={styles.regBtn} onClick={handleSubmit}>Change password</Button>
                      </div>                      
                    </div>
                  );
                }}
              />
            </div>
          </div>
        )}
      </div>
      <img className={styles.imgRight} src={imgRight} srcSet={`${imgRight2x} 2x`}  alt="right" />
      <Modal isShow={isShow} onClose={onClose}>
        {(error && errorCode != 409) && 
         <MessagePopup title={error ? 'Error' : 'Success'} message={error || message} handler={() => {
          onClose();             
        }} /> 
        }
        {(message || errorCode == 409) && <EmailCode oldPassword={oldPassword} newPassword={newPassword} handler={onClose} />}
      </Modal>
    </div>
  )
}