import React, { ReactNode, useState, useRef, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import cn from 'classnames';
import styles from './modal.module.scss';

interface IModalProps {
  children: ReactNode;
  isShow: boolean;
  onClose?: () => void;
  className?: string;
}

const Modal = ({ children, isShow, onClose, className = '' }: IModalProps) => {
  const modalRef = useRef(null);
  const handleOutsideClick = (event: MouseEvent) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target)
    ) {
      onClose();
    }
  };
  // useEffect(() => {
  //   document.addEventListener('click', handleOutsideClick);
  //
  //   return () => {
  //     document.removeEventListener('click', handleOutsideClick);
  //   };
  // }, []);
  if (!isShow) return null;
  return (
    <>
      <div ref={modalRef} className={cn(styles.container, className)}>
        <div className={styles.modal}>{children}</div>
      </div>
      <div className={styles.backdrop}>
        {onClose &&
          <button aria-label="close" onClick={onClose} className={styles.btn}>
            <CloseIcon />
          </button>
        }
      </div>
    </>
  );
};

export const useModal = () => {
  const [isShow, setIsShow] = useState(false);
  const onOpen = () => {
    setIsShow(true);
  };
  const onClose = () => {
    setIsShow(false);
  };
  return { isShow, onOpen, onClose, Modal };
};
