import './styles/global.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from './components/Header';
import { useModal } from './components/Modal';
import { ModalContext } from './context/modalContext';
import { Pages } from './pages';
import { getToken, regResponse } from './store/slices/authSlice';

const App = () => {
  const [scale, setScale] = useState(0);
  const dispatch = useDispatch();
  const { isShow, Modal, onOpen, onClose } = useModal();
  const token = useSelector(getToken);
  useEffect(() => {
    if (!token) {
      const localToken = localStorage.getItem('Token');
      const localMemberId = localStorage.getItem('MemberId');
      if (localToken) {
        dispatch(regResponse({ token: localToken, memberId: localMemberId }));
      } else {
        dispatch(regResponse({ token: null }));
      }
    }
  }, [token]);
  useEffect(() => {
    const resize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      let w = 1920;
      let h = 1080;
      //always use MAX-, not MIN- its important for == state
      // if (matchMedia('(max-aspect-ratio: 1/1)').matches){
      //   w = 780;
      //   h = 1130;
      // }
      const aspect = h / w;
      const size = Math.min(height / aspect, width);
      setScale(size / w);
    }
    window.addEventListener('resize', resize);
    resize();
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, []);

  useEffect(() => {
    document.body.style.setProperty('--base', scale.toString() + 'px');
  }, [scale]);

  return (
    <div className="app">
      <div className="appContainer">
        <ModalContext.Provider value={{ isShow, Modal, onOpen, onClose }}>
          <Header handlerAuth={onOpen} />
          <Pages />
        </ModalContext.Provider>
      </div>
    </div>
  );
};

export default App;
