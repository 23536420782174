import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Button } from '../../../components/Button';
import { TextAdapter } from '../../../components/TextAdapter';
import { TFields, action, changePasswordFields, recoverPasswordFields } from '../../Auth/configs';
import { Loader } from '../../../components/Loader';
import imgRight from './jack.png';
import imgRight2x from './jack_2x.png';
import styles from './setNewPassword.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useModal } from '../../../components/Modal';
import { MessagePopup } from '../../../components/MessagePopup/MessagePopup';
import { getRoute } from '../../../helpers/getRoute';
import { routes } from '../../../consts/routes';
import { mainAPI } from '../../../api/mainAPI';
import { serverMessages } from '../../../translates/serverMessages';

export const SetNewPassword = () => {
  const { isShow, Modal, onOpen, onClose } = useModal();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const {emailCode} = useParams();

  const fields: TFields[] = recoverPasswordFields;
  const onSubmit = (v: any) => {
    sendRequest(v.newPassword, emailCode);
  };

  const sendRequest = async (newPassword: string, emailCode: string) => {
    setIsFetching(true);
    setError('');
    setMessage('');
    try {
      const data = await mainAPI.recoverPassword({newPassword, emailCode});
      if (data) {
        setIsFetching(false);
        setMessage(serverMessages.passwordChanged);
        onOpen();
      }
    } catch (e) {
      setIsFetching(false);
      setError(e?.response?.data?.data?.error);
      onOpen();
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>Set new password</div>
        </div>
        {isFetching ? (
          <Loader />
        ) : (
          <div className={styles.gradientWrap}>
            <div className={styles.formContent}>
              <Form
              validate={(values: any) => {
                const errors: any = {};
                if (values['confirmPassword'] != values['newPassword']) {
                  errors['confirmPassword'] = 'error';
                }
                return errors;
              }}
                onSubmit={onSubmit}
                render={({ handleSubmit }) => {
                  return (
                    <div className={styles.formContainer}>
                      <form className={styles.form} onSubmit={handleSubmit}>
                        {fields.map(({ name, label, type, validate, helper }) => (
                          <div key={name} className={styles.fieldContainer}>
                            <Field
                              type={type}
                              component={TextAdapter}
                              validate={validate}
                              name={name}
                              helper={helper}
                              label={label}                              
                              customStyles={styles}
                            />
                          </div>
                        ))}
                      </form>
                      <div className={styles.buttonsWrap}>
                        <Button className={styles.regBtn} onClick={handleSubmit}>Change password</Button>
                      </div>                      
                    </div>
                  );
                }}
              />
            </div>
          </div>
        )}
      </div>
      <img className={styles.imgRight} src={imgRight} srcSet={`${imgRight2x} 2x`}  alt="right" />
      <Modal isShow={isShow}>
        <MessagePopup title={error ? 'Error' : 'Success'} message={error || message} handler={() => {
          onClose();   
          if(!error) {
            navigate(getRoute(routes.HOME));
          }     
        }} />
      </Modal>
    </div>
  )
}